import * as moment from 'moment';
import {PLACE_PARSERS} from './place';
import {ROUTE_PARSERS} from './route';
import {COMMUTE_PARSERS} from './commute';
import * as _ from 'lodash';

const parserConfig = {
  routes: ROUTE_PARSERS,
  places: PLACE_PARSERS,
  commutes: COMMUTE_PARSERS,
};

function getDefaultConfig() {
  return parserConfig.commutes;
}

export function applyGeneralFilter(obj, filtersData) {
  const momentObj = moment.utc(obj.creationTime);
  const weekday = momentObj.isoWeekday() - 1;
  const hours = momentObj.hours();
  const minutes = momentObj.minutes();
  const seconds = momentObj.seconds();
  return filtersData.weekdays[weekday] && hours >= filtersData.hours.from
    && hours * 3600 + minutes * 60 + seconds <= filtersData.hours.to * 3600;
}

export function parseData(data, selectedMGObject, filtersData, filter) {
  const config = selectedMGObject === null ? getDefaultConfig() : parserConfig[selectedMGObject];

  const selectedData = data[selectedMGObject];
  return {
    headers: config.headers,
    values: Object.keys(selectedData).reduce((red, key) => {
      const obj = selectedData[key];
      if ((!filter || (filter && filter(obj))) && applyGeneralFilter(obj, filtersData)) {
        red.push(parse(obj, config, data));
      }
      return red;
    }, []),
  };
}

function parse(obj, typeParsers, data) {
  const parsers = {
    ...typeParsers.parsers,
    'id': { path: `id` },
  };
  return Object.keys(parsers).reduce((red, key) => {
    const { parser, path, async } = parsers[key];
    const value = _.get(obj, path);
    red[key] = { value, parser, async };
    return red;
  }, {});
}
