import {createSelector} from 'reselect';
import {getFilters} from '../filters/getters';
import {parseData} from './table-parsers';
import {getSelectedMGObjectType, getSelectedCommuteId, getSelectedPlaceId, getSelectedRouteId}
  from '../selectedMGObject/getters';
import MobilityGraphType from "../selectedMGObject/MobilityGraphType";

export const getCloudSyncData = state => state.cloudSyncData;

export const getTableData = createSelector(
  getCloudSyncData,
  getSelectedMGObjectType,
  getFilters,
  (cloudSyncData, selectedMGObjectType, filters) => {
    if (cloudSyncData === null) {
      return null;
    }
    return parseData(cloudSyncData, selectedMGObjectType, filters);
  },
);

export const getSelectedCommute = createSelector(
  [getSelectedCommuteId, getCloudSyncData, getFilters],
  (selectedCommuteId, cloudSyncData, filters) => {
    if (!selectedCommuteId) {
      return;
    }

    const commute = cloudSyncData.commutes[selectedCommuteId];

    const routes = Object.keys(cloudSyncData.routes).reduce((result, key) => {
      if (cloudSyncData.routes[key].commuteId === selectedCommuteId) {
        result.push(cloudSyncData.routes[key]);
      }
      return result;
    }, []);

    return { commute, routes };
  },
);

export const getSelectedPlace = createSelector(
  [getSelectedPlaceId, getCloudSyncData, getFilters],
  (selectedPlaceId, cloudSyncData, filters) => {
    if (selectedPlaceId === null) {
      return;
    }

    const isRelatedCommute = (commute) => {
      return commute.startPlaceId === selectedPlaceId || commute.endPlaceId === selectedPlaceId;
    };
    const place = cloudSyncData.places[selectedPlaceId];

    return {
      place,
      tableData: parseData(cloudSyncData, MobilityGraphType.COMMUTE, filters, isRelatedCommute),
    };
  },
);

export const getSelectedRoute = createSelector(
  [getSelectedRouteId, getCloudSyncData],
  (selectedRouteId, cloudSyncData) => {
    if (selectedRouteId === null) {
      return null;
    }

    return cloudSyncData.routes[selectedRouteId];
  },
);
