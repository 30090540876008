import * as moment from 'moment';
import {PROXY_URL} from '../config';
import { getUserIdByUser } from '../state/user/getters';

export class RequestError extends Error {
  data = null;

  constructor(message, data) {
    super(message);
    this.data = data;
  }
}

export async function mgFetchPost(url, body) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  };
  let res;
  try {
    res = await fetch(url, requestOptions);
  } catch (e) {
    throw new Error('Server error.');
  }

  if (res.status === 200) {
    return res.json();
  } else {
    try {
      res = await res.json();
    } catch(e) {
      throw new Error('Server error.');
    }
    throw new RequestError('Server error.', res);
  }
}

const ENV = 'cit';
export const defaultUrl = `https://sync.pnav-set.${ENV}.cc.api.here.com/profile`;
export const defaultPredictionsUrl = `https://predict.pnav-set.${ENV}.cc.api.here.com/v1/`;
export const allDataBody = {
  data: [],
  count: 0,
  lastSynchronizationTimestampMs: 0,
  'clientData': '',
};

export function proxyFetch(token, url = defaultUrl, body = allDataBody, method = 'POST') {
  const proxyUrl = getProxyUrl(url);
  const options = {
    method,
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  };

  if (method !== 'GET') {
    options.body = typeof body === 'string' ? body : JSON.stringify(body);
  }
  return fetch(proxyUrl, options);
}

export async function fetchPredictionDestinations(user, formData, url = defaultPredictionsUrl + 'destination') {
  return (await proxyFetch(user.token, url, {
    location: { latitude: formData.coords.lat, longitude: formData.coords.lng },
    time: moment(new Date(`${formData.date}T${formData.time}${formData.zone}`)).utc().format('YYYY-MM-DDTHH:mm:ss'),
    predictionMaxSize: formData.maxDestsPredicted,
    userid: getUserIdByUser(user),
  })).json()
}

export async function fetchPredictionRoutes(user, formData, destinations, url = defaultPredictionsUrl + 'route') {
  return (await proxyFetch(user.token, url, {
    location: { latitude: formData.coords.lat, longitude: formData.coords.lng },
    time: moment(new Date(`${formData.date}T${formData.time}${formData.zone}`)).utc().format('YYYY-MM-DDTHH:mm:ss'),
    predictionMaxSize: formData.maxRoutesPredicted,
    userid: getUserIdByUser(user),
    destinations,
  })).json()
}

export async function fetchDepartureWindows(user, formData, url = defaultPredictionsUrl + 'departure-window') {
  return (await proxyFetch(user.token, url, {
    location: { latitude: formData.coords.lat, longitude: formData.coords.lng },
    time: moment(new Date(`${formData.date}T${formData.time}${formData.zone}`)).utc().format('YYYY-MM-DDTHH:mm:ss'),
    predictionMaxSize: formData.maxDestsPredicted,
    userid: getUserIdByUser(user),
  })).json()
}

export function getProxyUrl(url) {
  return `${PROXY_URL}/proxy?url=${encodeURIComponent(url)}`;
}
