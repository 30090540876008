import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseFloatingPanel from "../closeFloatingPanel";
import StartEnd from "../startEnd";
import Collapse from "../../collapse";
import "./style.css";
import Json from '../../shared/json/Json';

export default class SelectedRoute extends Component {
  render() {
    const { selectedRoute, onClose } = this.props;
    return <div className="mg-floating-selected-route">
      <CloseFloatingPanel onClose={onClose} />
      <h2>ROUTE ID: {selectedRoute.id}</h2>
      <StartEnd start={selectedRoute.start} end={selectedRoute.end} />
      <Collapse label="View json object" hidden={!selectedRoute.raw}>
        <Json data={selectedRoute.raw} />
      </Collapse>
    </div>;
  }
}

SelectedRoute.propTypes = {
  selectedRoute: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
