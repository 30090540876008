import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getSelectedPlace, getSelectedCommute, getSelectedRoute} from '../../state/cloudSyncData/getters';
import FloatingPanel from './floatingPanel';
import SelectedPlace from './selectedPlace';
import SelectedCommute from './selectedCommute';
import SelectedRoute from './selectedRoute';
import { selectRoute, selectCommute, selectPlace } from '../../state/selectedMGObject/reducer';
import { getHoveredId } from "../../state/getters";
import { setHoveredCommute } from "../../state/hoveredCommute/reducer";

class FloatingPanels extends Component {
  render() {
    const {
      selectedPlaceId,
      selectedCommuteId,
      selectedRouteId,
      selectedPlace,
      selectedCommute,
      selectedRoute,
      selectPlace,
      selectCommute,
      selectRoute,
      setHoveredCommute,
      hoveredId,
    } = this.props;

    return <div>
      {selectedPlaceId === null ||
        <FloatingPanel level={1}>
          <SelectedPlace
            selectedPlace={selectedPlace}
            onClose={() => selectPlace(null)}
            hoveredId={hoveredId}
            selectCommute={selectCommute}
            setHoveredCommute={setHoveredCommute}
          />
        </FloatingPanel>
      }

      {selectedCommuteId === null ||
        <FloatingPanel level={selectedCommuteId === null || selectedPlaceId === null ? 1 : 2}>
          <SelectedCommute selectedCommute={selectedCommute} onClose={() => selectCommute(null)} />
        </FloatingPanel>
      }

      {selectedRouteId === null ||
        <FloatingPanel level={1}>
          <SelectedRoute selectedRoute={selectedRoute} onClose={() => selectRoute(null)} />
        </FloatingPanel>
      }
    </div>
  }
}

const mapStateToProps = state => ({
  selectedCommuteId: state.selectedMGObject.selectedCommuteId,
  selectedPlaceId: state.selectedMGObject.selectedPlaceId,
  selectedRouteId: state.selectedMGObject.selectedRouteId,
  selectedPlace: getSelectedPlace(state),
  selectedCommute: getSelectedCommute(state),
  selectedRoute: getSelectedRoute(state),
  hoveredId: getHoveredId(state),
});

const mapDispatchToProps = dispatch => ({
  // setCloudSyncData: bindActionCreators(setCloudSyncData, dispatch),
  selectRoute: bindActionCreators(selectRoute, dispatch),
  selectCommute: bindActionCreators(selectCommute, dispatch),
  selectPlace: bindActionCreators(selectPlace, dispatch),
  setHoveredCommute: bindActionCreators(setHoveredCommute, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FloatingPanels);
