import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/fontawesome-free-solid';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import './style.css';

export default class Collapse extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    if (this.props.hidden) {
      return null;
    }
    return <div className="mg-collapse">
      <div className="label" onClick={() => this.toggle()}>
        <span>{this.props.label}</span>
        <FontAwesomeIcon icon={this.state.isOpen ? faChevronUp : faChevronDown} />
      </div>
      <div className={'container' + (this.state.isOpen ? ' open' : '')}>
        {this.props.children}
      </div>
    </div>;
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
}

Collapse.propTypes = {
  label: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
};
