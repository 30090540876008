const SET_IS_LOADING = 'subscriptions/ui/SET_IS_LOADING';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
}

export const setSubscrIsLoading = (key, isLoading) => ({
  type: SET_IS_LOADING,
  payload: { key, value: isLoading },
});

export const getSubscrIsLoading = (state) => {
  const uiState = state.subscriptions.ui;
  return !!Object.keys(uiState).find((key) => uiState[key]);
};
