import {parsePlaces} from './place';
import {parseCommutes} from './commute';
import {parseRoutes} from './route';

export function parseFileData(data) {
  const places = parsePlaces(data.dbPlaces);
  const commuteRes = parseCommutes(data.dbCommutes, places);
  const routes = parseRoutes(data.dbRoutes, commuteRes.commutes);

  return { places, ...commuteRes, routes };
}
