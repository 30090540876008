const NS = 'file';

export const FILE_UPLOAD = `${NS}/upload`;
export const FILE_CLOSE = `${NS}/close`;

export default function (state = null, action) {
  switch (action.type) {
    case FILE_UPLOAD:
      return action.payload.file;
    case FILE_CLOSE:
      return null;
    default:
      return state;
  }
}

export function uploadFile(payload) {
  return { type: FILE_UPLOAD, payload };
}

export function closeFile() {
  return { type: FILE_CLOSE };
}
