import {LOGOUT} from '../user/reducer';
import {parseOnlineData} from './online-parser';
import {FILE_CLOSE, FILE_UPLOAD} from '../file/reducer';
import {parseFileData} from './file-parser';

const NS = 'cloudSyncData';
const SET = `${NS}/SET`;

export default function (state = null, action) {
  switch (action.type) {
    case SET:
      return parseOnlineData(action.payload);
    case FILE_UPLOAD:
      return parseFileData(action.payload.data);
    case LOGOUT:
    case FILE_CLOSE:
      return null;
    default:
      return state;
  }
}

export function setCloudSyncData(data) {
  return { type: SET, payload: data };
}
