import {LOGOUT} from '../user/reducer';
import {FILE_CLOSE} from '../file/reducer';

const NS = 'selectedMGObjects';
const SET_MG_OBJECT = `${NS}/SET_MG_OBJECT`;
const SELECT_COMMUTE = `${NS}/SELECT_COMMUTE`;
const SELECT_PLACE = `${NS}/SELECT_PLACE`;
const SELECT_ROUTE = `${NS}/SELECT_ROUTE`;

const getDefaultState = () => ({
  type: 'commutes',
  selectedCommuteId: null,
  selectedPlaceId: null,
  selectedRouteId: null,
});

export default function (state = getDefaultState(), action) {
  switch (action.type) {
    case SET_MG_OBJECT:
      return {
        ...state,
        type: action.payload
      };
    case SELECT_COMMUTE:
      return {
        ...state,
        selectedCommuteId: action.payload,
      };
    case SELECT_PLACE:
      return {
        ...state,
        selectedPlaceId: action.payload,
      };
    case SELECT_ROUTE:
      return {
        ...state,
        selectedRouteId: action.payload,
      };
    case LOGOUT:
    case FILE_CLOSE:
      return getDefaultState();
    default:
      return state;
  }
}

export function setMGObject(value) {
  return { type: SET_MG_OBJECT, payload: value };
}

export function selectCommute(commuteId) {
  return { type: SELECT_COMMUTE, payload: commuteId };
}

export function selectPlace(placeId) {
  return { type: SELECT_PLACE, payload: placeId };
}

export function selectRoute(routeId) {
  return { type: SELECT_ROUTE, payload: routeId };
}
