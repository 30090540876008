export function getInitState(key) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch(e) {
    return null;
  }
}

export function updateLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
  return value;
}
