import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './filters.css';
import Slider from '../../shared/slider/Slider';
import Weekdays from './weekdays/Weekdays';

export default class Filters extends Component {
  sliderOptions = {
    defaultValue: [0, 24],
    step: 1,
    marks: {
      0: '0',
      3: '3',
      6: '6',
      9: '9',
      12: '12',
      15: '15',
      18: '18',
      21: '21',
      24: '24',
    },
    max: 24,
  };

  onWeekdayChange(weekdays) {
    this.props.updateFilters({ ...this.props.filters, weekdays });
  }

  onSliderChange(range) {
    this.props.updateFilters({
      ...this.props.filters,
      hours: {
        from: range[0],
        to: range[1],
      }
    })
  }

  render() {
    const { filters } = this.props;

    return (
      <div className="mg-filter">
        <Weekdays weekdays={this.props.filters.weekdays} onChange={this.onWeekdayChange.bind(this)} />
        <button className="mg-reset" onClick={this.props.resetFilters}>Reset</button>
        <div className="mg-slider">
          <input type="text" readOnly value={this.getHours(filters.hours.from)}/>
          <Slider
            {...this.sliderOptions}
            onChange={this.onSliderChange.bind(this)}
            value={[filters.hours.from, filters.hours.to]}
          />
          <input type="text" readOnly value={this.getHours(filters.hours.to)}/>
        </div>
      </div>
    );
  }

  getHours(value) {
    const dayPeriod = value > 12 ? 'PM' : 'AM';
    let periodHours = `${value > 12 ? value - 12 : value}`;
    if (periodHours.length < 2) {
      periodHours = `0${periodHours}`;
    }
    return `${periodHours}:00 ${dayPeriod}`;
  }
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};
