import {addressParser, numberParser} from './common-parsers';

export const COMMUTE_PARSERS = {
  headers: [
    'Start Place',
    'End Place',
    '# of routes',
    'Total Score',
  ],
  parsers: {
    'Start Place': { path: `start`, parser: addressParser, async: true },
    'End Place': { path: `end`, parser: addressParser, async: true },
    '# of routes': { path: `routesCount` },
    'Total Score': { path: `score`, parser: numberParser },
  },
};
