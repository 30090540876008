import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class FloatingPanel extends Component {
  render() {
    const panelWidth = 578;
    const leftTransparentWidth = this.props.level * 120;
    const floatingPanelWidth = panelWidth + leftTransparentWidth;

    return <div
      className="mg-floating-panel"
      style={{ width: floatingPanelWidth/10 + 'rem' }}
    >
      <div className="left-transparent" style={{ width: leftTransparentWidth/10 + 'rem' }}/>
      <div
        className="panel"
        style={{ left: leftTransparentWidth/10 + 'rem', width: 'calc(100% - ' + (leftTransparentWidth + 20)/10 + 'rem)' }}>
        {this.props.children}
      </div>
    </div>;
  }
}

FloatingPanel.defaultProps = {
  level: 1,
};

FloatingPanel.propTypes = {
  level: PropTypes.number.isRequired,
};
