import React, { Component } from 'react';
import Left from '../shared/left/Left';
import Map from '../shared/map/Map';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';
import PredictionsMap from './map/PredictionsMap';
import PredictionsLeft from "./predictionsLeft/PredictionsLeft";
import { setFormData } from "../../state/predictions/formData/reducer";
import {fetchDepartureWindows, fetchPredictionDestinations, fetchPredictionRoutes} from '../../utils/fetch';
import {
  setPredictionDepartureWindowData, setPredictionDestinationsData,
  setPredictionRoutesData,
} from '../../state/predictions/response/reducer';
import { setHoveredPrediction } from "../../state/predictions/hoveredPrediction/reducer";
import { setHoveredPredictionRoute } from "../../state/predictions/hoveredRoute/reducer";
import { setSelectedPrediction } from "../../state/predictions/selectedPrediction/reducer";

class Predictions extends Component {
  constructor(...args) {
    super(...args);
    this.setPredictionDestinationsData = this.setPredictionDestinationsData.bind(this);
  }

  render() {
    return (
      <div>
        <Left>
          <PredictionsLeft
            formData={this.props.formData}
            setFormData={this.props.setFormData}
            setHoveredPrediction={this.props.setHoveredPrediction}
            setPredictionDestinationsData={this.setPredictionDestinationsData}
            response={this.props.response}
            hoveredPrediction={this.props.hoveredPrediction}
            hoveredPredictionRoute={this.props.hoveredPredictionRoute}
            selectedPrediction={this.props.selectedPrediction}
            setHoveredPredictionRoute={this.props.setHoveredPredictionRoute}
            setSelectedPrediction={this.props.setSelectedPrediction}
            uiZoom={this.props.uiZoom}
          />
        </Left>
        <Map>
          <PredictionsMap
            state={this.props.state}
            setFormData={this.props.setFormData}
            setHoveredPrediction={this.props.setHoveredPrediction}
            setHoveredPredictionRoute={this.props.setHoveredPredictionRoute}
            setSelectedPrediction={this.props.setSelectedPrediction}
          />
        </Map>
      </div>
    );
  }

  async setPredictionDestinationsData() {
    const { user, formData, setPredictionDepartureWindowData, setPredictionDestinationsData, setPredictionRoutesData } = this.props;

    if (!!formData.coords) {
      setPredictionDepartureWindowData(await fetchDepartureWindows(user, formData));

      const predictions = await fetchPredictionDestinations(user, formData);
      setPredictionDestinationsData(predictions);

      const locations = predictions._embedded.prediction.map(prediction => prediction.destination.Identifier);

      setPredictionRoutesData(this.orderFetchedRoutes(predictions, await fetchPredictionRoutes(user, formData, locations)));
    }
  }

  orderFetchedRoutes(destinations, routes) {
    const orderedPredictions = destinations._embedded.prediction.map(destinationPrediction =>
      _.find(routes._embedded.prediction, routePrediction =>
        destinationPrediction.destination.Identifier === routePrediction.destination.Identifier));
    routes._embedded.prediction = orderedPredictions;
    return routes;
  }
}

const mapStateToProps = state => ({
  state: state,
  formData: state.predictions.formData,
  response: state.predictions.response,
  hoveredPrediction: state.predictions.hoveredPrediction,
  user: state.user,
  hoveredPredictionRoute: state.predictions.hoveredPredictionRoute,
  selectedPrediction: state.predictions.selectedPrediction,
  uiZoom: state.ui.uiZoom,
});

const mapDispatchToProps = dispatch => ({
  setFormData: bindActionCreators(setFormData, dispatch),
  setPredictionDestinationsData: bindActionCreators(setPredictionDestinationsData, dispatch),
  setPredictionRoutesData: bindActionCreators(setPredictionRoutesData, dispatch),
  setPredictionDepartureWindowData: bindActionCreators(setPredictionDepartureWindowData, dispatch),
  setHoveredPrediction: bindActionCreators(setHoveredPrediction, dispatch),
  setHoveredPredictionRoute: bindActionCreators(setHoveredPredictionRoute, dispatch),
  setSelectedPrediction: bindActionCreators(setSelectedPrediction, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Predictions));
