import { combineReducers } from 'redux';
import host from './host/reducer';
import isActive from './isActive/reducer';
import subscriptions from './subscriptions/reducer';
import debug from './debug/reducer';
import ui from './ui/reducer';

export default combineReducers({
  host,
  isActive,
  subscriptions,
  debug,
  ui,
});
