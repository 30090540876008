import {createCommuteRoute, createFinishMarker, createGroup, createMarker, createStartMarker} from '../../shared/map/utils';
import {Drawer} from '../../shared/map/utils/drawer';
import * as _ from 'lodash';
import {applyGeneralFilter} from '../../../state/cloudSyncData/table-parsers';

export class PlacesDrawer extends Drawer {
  hoveredGroup = createGroup({
    zIndex: 1,
  });

  constructor(map, group, drawers, onHoverCb, onClickCb) {
    super(map, group);
    this.drawers = drawers;
    this.onHoverCb = onHoverCb;
    this.onClickCb = onClickCb;
  }

  draw(state) {
    if (state.selectedMGObject.selectedPlaceId) {
      return this.drawers.commutes.draw(state);
    }
    const mapObjects = [];
    Object.keys(state.cloudSyncData.places).forEach(key => {
      const place = state.cloudSyncData.places[key];
      if (!applyGeneralFilter(place, state.filters)) {
        return;
      }
      mapObjects.push(
        this.addMarkerListenersAndData(createMarker(place.point), place.id)
      );
    });

    this.group.addObjects(mapObjects);
  }

  update(state) {
    if (state.selectedMGObject.selectedPlaceId) {
      return this.drawers.commutes.update(state);
    } else if (this.prevState && this.prevState.hoveredPlace !== state.hoveredPlace) {
      this.hoverPlace(state.hoveredPlace, state.cloudSyncData);
    }

    super.update(state);
  }

  hoverPlace(hoveredPlace, cloudSyncData) {
    this.hoveredGroup.removeAll();
    if (this.group.contains(this.hoveredGroup)) {
      this.group.removeObject(this.hoveredGroup);
    }
    if (hoveredPlace !== null) {
      const place = cloudSyncData.places[hoveredPlace];
      this.hoveredGroup.addObject(this.addHighlightedMarkerListenersAndData(createFinishMarker(place.point), place.id));
      const placeCommutesIndex = cloudSyncData.placesCommutesIndex[hoveredPlace];
      if (placeCommutesIndex) {
        [...placeCommutesIndex.start, ...placeCommutesIndex.end].forEach(commuteId => {
          const commute = cloudSyncData.commutes[commuteId];

          this.hoveredGroup.addObjects([
            createCommuteRoute(commute),
            createStartMarker(_.isEqual(place.point, commute.start) ? commute.end : commute.start),
          ]);
        });
      }
      this.group.addObject(this.hoveredGroup);
    }
  }

  addMarkerListenersAndData(mapObject, id) {
    mapObject.addEventListener('pointerenter', this.onPointerEnter.bind(this));
    mapObject.setData({ id });

    return mapObject;
  }

  addHighlightedMarkerListenersAndData(mapObject, id) {
    mapObject.addEventListener('pointerleave', this.onPointerLeave.bind(this));
    mapObject.addEventListener('tap', this.onClick.bind(this));
    mapObject.setData({ id });

    return mapObject;
  }

  onPointerEnter({ target }) {
    this.onHoverCb(target.getData().id);
    this.map.getElement().style.cursor = 'pointer';
  }

  onPointerLeave() {
    this.onHoverCb(null);
    this.map.getElement().style.cursor = 'auto';
  }

  onClick({ target }) {
    this.onClickCb(target.getData().id);
  }
}
