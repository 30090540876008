import { SET } from './constants';

export default (state = 'https://notifications.pnav-set.sit.cc.api.here.com', action) => {
  switch (action.type) {
    case SET:
      return action.payload;
    default:
      return state;
  }
}

export const setHost = (host) => ({ type: SET, payload: host });

export const getSubscriptionsHost = (state) => state.subscriptions.host;
