import Json from '../shared/json/Json';
import React from 'react';
import axios from 'axios';
import { getProxyUrl } from '../../utils/fetch';
import PropTypes from 'prop-types';

function DebugNotifications({ addNotification, pseudoUserId }) {
  const [url, setUrl] = React.useState(`${URL}/isalive`);
  const [res, setRes] = React.useState(null);

  const request = (method) => {
    return async () => {
      axios({
        url: getProxyUrl(url),
        method,
      }).then((res) => {
        if (res.headers['content-type'].startsWith('text/plain')) {
          setRes({ textResponse: res.data });
        } else {
          setRes(res.data);
        }
      }).catch((e) => {
        addNotification(e.response.data, { impact: 'negative' });
      });
    }
  };

  return (
    <div className="mg-debug-subscriptions">
      <h3>Debug</h3>
      <UrlSelect onChange={setUrl} pseudoUserId={pseudoUserId} />
      <lui-desktoplayer-theme className="mg-app">
        <lui-contextchromelayer-theme>
          <lui-textfield>
            <input value={url} disabled placeholder="Url" />
          </lui-textfield>
        </lui-contextchromelayer-theme>
      </lui-desktoplayer-theme>
      <div className="mg-debug-subscriptions-actions">
        <lui-button secondary onClick={request('GET')}>GET</lui-button>
      </div>
      <div className="mg-debug-subscriptions-res">
        <Json data={res} />
      </div>
    </div>
  );
}

DebugNotifications.propTypes = {
  addNotification: PropTypes.func.isRequired,
  pseudoUserId: PropTypes.string.isRequired,
};

const URL = 'https://fake-dependencies-pnav-pat-10.routing.ext.here.com';
const endpoints = [
  { value: '/isalive', attachPseudoUserId: false },
  { value: '/notifications-count/', attachPseudoUserId: true },
  { value: '/notifications/', attachPseudoUserId: true },
];

function UrlSelect({ pseudoUserId, onChange }) {
  const [endpoint, setEndpoint] = React.useState(endpoints[0].value);

  const changeEndpoint = (e) => {
    const { value } = e.target;
    const endpoint = endpoints.find((e) => e.value === value);
    onChange(`${URL}${value}${endpoint.attachPseudoUserId ? pseudoUserId : ''}`);
    setEndpoint(value);
  };

  return (
    <select value={endpoint} onChange={changeEndpoint}>
      {endpoints.map((endpoint) => (
        <option key={endpoint.value} value={endpoint.value}>{endpoint.value}</option>
      ))}
    </select>
  );
}

export default DebugNotifications;
