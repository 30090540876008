import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './tabs.css';

export default class Tabs extends Component {
  render() {
    const { tabs, onClick, value } = this.props;
    const tabItems = tabs.map(tab => {
      const classes = classnames('mg-tabs-item', {
        'mg-tabs-item-selected': value === tab.value,
      });
      return (
        <li
          className={classes}
          onClick={onClick.bind(this, tab.value)}
          key={tab.value}
        >{tab.label}</li>
      )
    });
    return (
      <ul className="mg-tabs">
        {tabItems}
      </ul>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
