import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Filters from '../filters/Filters';
import Tabs from '../../shared/tabs/Tabs';
import Table from '../../shared/table/Table';
import './analysisLeft.css';
import tableDataShape from '../../shared/table/tableDataShape';

export default class AnalysisLeft extends Component {
  render() {
    const tabs = [
      { label: `Commutes`, value: 'commutes' },
      { label: `Places`, value: 'places' },
      { label: `Routes`, value: 'routes' },
    ];

    return (
      <div className="mg-analysis-left">
        <Filters
          filters={this.props.filters}
          updateFilters={this.props.updateFilters}
          resetFilters={this.props.resetFilters}
        />
        <div className="mg-analysis-left-tabs">
          <Tabs
            tabs={tabs}
            onClick={this.props.setMGObject}
            value={this.props.selectedMGObject}
          />
          {this.props.tableData &&
            <Table
              data={this.props.tableData}
              onClick={this.onSelectObject.bind(this)}
              onHover={this.onHoverObject.bind(this)}
              hoveredId={this.props.hoveredId}
            />
          }
        </div>
      </div>
    );
  }

  onSelectObject(id) {
    const { selectedMGObject, selectCommute, selectPlace, selectRoute } = this.props;
    switch (selectedMGObject) {
      case 'commutes':
        return selectCommute(id);
      case 'places':
        return selectPlace(id);
      case 'routes':
        return selectRoute(id);
      default:
        throw new Error(`Unsupported MG Object type: "${selectedMGObject}"`);
    }
  }

  onHoverObject(id) {
    const { selectedMGObject, setHoveredCommute, setHoveredPlace, setHoveredRoute } = this.props;
    switch (selectedMGObject) {
      case 'commutes':
        return setHoveredCommute(id);
      case 'places':
        return setHoveredPlace(id);
      case 'routes':
        return setHoveredRoute(id);
      default:
        throw new Error(`Unsupported MG Object type: "${selectedMGObject}"`);
    }
  }
}

AnalysisLeft.propTypes = {
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  setMGObject: PropTypes.func.isRequired,
  selectedMGObject: PropTypes.string.isRequired,
  tableData: tableDataShape,
  selectCommute: PropTypes.func.isRequired,
  selectPlace: PropTypes.func.isRequired,
  selectRoute: PropTypes.func.isRequired,
  setHoveredCommute: PropTypes.func.isRequired,
  setHoveredRoute: PropTypes.func.isRequired,
  setHoveredPlace: PropTypes.func.isRequired,
  hoveredId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
