import {combineReducers} from 'redux';
import login from './login/reducer';
import uiZoom from './uiZoom/reducer';
import notification from './notification/reducer';

export default combineReducers({
  login,
  uiZoom,
  notification,
});
