import React from 'react';
import PropTypes from 'prop-types';
import './addSubscription.scss';

class AddSubscription extends React.Component {
  static propTypes = {
    pos: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }).isRequired,
    latLng: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    onAdd: PropTypes.func.isRequired,
  };

  onAdd = () => {
    const { onAdd, latLng } = this.props;
    onAdd(latLng);
  };

  render() {
    const { pos } = this.props;

    return (
      <div className="mg-add-subscription" style={{ top: pos.y, left: pos.x }}>
        <lui-button onClick={this.onAdd}>AddSubscription</lui-button>
      </div>
    );
  }
}

export default AddSubscription;
