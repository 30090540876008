import * as _ from 'lodash';
import { totalScoreParser } from './common-parsers';

export function commuteParser(rawCommute, rawDataById) {
  const commuteId = _.get(rawCommute, 'objectData.Model.Identifier');
  const startPlaceId = _.get(rawCommute, 'objectData.Model.StartPlace.ObjectIdentifier');
  const endPlaceId = _.get(rawCommute, 'objectData.Model.EndPlace.ObjectIdentifier');

  return {
    id: commuteId,
    start: placeAddressParser(startPlaceId, rawDataById),
    end: placeAddressParser(endPlaceId, rawDataById),
    score: totalScoreParser(_.get(rawCommute, 'objectData.LearnedDatas')),
    creationTime: _.get(rawCommute, 'objectData.Model.OnDeviceCreationTimeMs'),
    scoreDistribution: commuteScoreDistribution(rawCommute),
    startPlaceId,
    endPlaceId,
    routesCount: routesCountParser(commuteId, rawDataById),
    raw: rawCommute,
  };
}

function placeAddressParser(objectIdentifier, data) {
  const placeObj = data[objectIdentifier];
  return addressParser(_.get(placeObj, 'objectData.Model.Position.Coordinates', {}));
}

function addressParser(coords) {
  return {
    lat: coords.latitude,
    lng: coords.longitude,
  };
}

function commuteScoreDistribution(rawCommute) {
  const weekDays = [];
  const learnedDatas = rawCommute.objectData.LearnedDatas;

  learnedDatas.forEach(learnedData => {
    if (!learnedData.ScoreDistributions) {
      return;
    }

    learnedData.ScoreDistributions.forEach(scoreDistribution => {
      if (!scoreDistribution.Entries) {
        return;
      }

      scoreDistribution.Entries.forEach(entry => {
        const hour = entry.Key.TimeBucketId % 2 ? entry.Key.TimeBucketId - 1 : entry.Key.TimeBucketId;
        if (!weekDays[entry.Key.Weekday]) {
          weekDays[entry.Key.Weekday] = [];
        }
        if (!weekDays[entry.Key.Weekday][hour]) {
          weekDays[entry.Key.Weekday][hour] = [];
        }
        weekDays[entry.Key.Weekday][hour].push({
          score: Math.exp(entry.Score),
          hour: entry.Key.TimeBucketId,
      });
      });
    });
  });

  return weekDays;
}

function routesCountParser(objectIdentifier, data) {
  return Object.keys(data).filter(key => {
    const obj = data[key];
    return _.get(obj, 'objectData.ModelType') === 'Route' &&
      _.get(obj, 'objectData.Model.Transition.ObjectIdentifier') === objectIdentifier;
  }).length;
}
