import React from 'react';
import PropTypes from 'prop-types';
import './Notification.scss';
import { get } from 'lodash';

export default class Notifications extends React.Component {
  static propTypes = {
    notification: PropTypes.shape({
      title: PropTypes.string.isRequired,
      options: PropTypes.shape({
        impact: PropTypes.string,
        timeout: PropTypes.string,
      }),
    }),
  };
  static notificationsRef;

  static getDerivedStateFromProps(nextProps, prevState) {
    const ref = get(Notifications, 'notificationsRef.current');
    const { notification } = nextProps;
    if (notification !== prevState.notification && ref) {
      Notifications.addNotification(ref, notification);
    }

    return { notification };
  }

  static addNotification(ref, notification) {
    ref.add(notification.title, {
      impact: get(notification, 'options.impact', 'positive'),
      timeout: get(notification, 'options.timeout', false),
    });
  }

  state = { notification: null };

  constructor(props) {
    super(props);
    Notifications.notificationsRef = React.createRef();
  }

  componentDidMount() {
    const { notification } = this.state;
    const ref = get(Notifications, 'notificationsRef.current');
    if (notification) {
      Notifications.addNotification(ref, notification);
    }
  }


  render() {
    return (
      <lui-notification-group class="notifications" ref={Notifications.notificationsRef} />
    );
  }
}
