import * as _ from 'lodash';
import Rx from 'rxjs/Rx';

const LOCAL_STORAGE_KEY = 'mg-address-2';

const ADDRESSES = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};

export function getAddress(latLng) {
  if (!ADDRESSES[latLng]) {
    return loadAddress(latLng);
  }

  return Rx.Observable.of(ADDRESSES[latLng]);
}

function loadAddress(latLng) {
  const url = `https://geocoder.cit.api.here.com/6.2/search.json?` +
    `app_id=inbuPh7Masah2REqukES&app_code=M5AXNLjqWNW5teWzgw1nyQ&searchtext=${latLng}`;
  return Rx.Observable.create(async (observer) => {
    const res = await fetch(url);
    const jsonRes = await res.json();
    const results = _.get(jsonRes, 'Response.View.0.Result', []);
    let address = null;
    results.find(result => {
      const label = _.get(result, 'Location.Address.Label');
      if (label) {
        address = label;
        return true;
      }
      return false;
    });
    updateLocalStorage(latLng, address || latLng);
    observer.next(address || latLng);
    observer.complete();
  });
}


function updateLocalStorage(latLng, address) {
  ADDRESSES[latLng] = address;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(ADDRESSES));
}
