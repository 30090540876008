import React, {Component} from 'react';
import './login.css';
import { faEnvelope, faLock, faUser } from '@fortawesome/fontawesome-free-solid';
import RadioGroup from '../shared/radioGroup/RadioGroup';
import PropTypes from 'prop-types';
import FormControl from '../shared/formControl/FormControl';
import Spinner from '../shared/spinner/Spinner';
import InputGroup from './inputGroup/InputGroup';
import Button from '../shared/button/Button';
import FileLoader from './fileLoader/FileLoader';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '4',
      username: '',
      userId: '',
      password: '',
    };
  }

  onVersionChange(version) {
    this.setState({ version });
  }

  onInputChange(key, e) {
    this.setState({ [key]: e.target.value });
  }

  render() {
    const { ui } = this.props;
    const versionRadioOptions = [
      { label: 'Version 4', value: '4' },
      { label: 'Version 5', value: '5' },
    ];
    return (
      <div className="mg-login">
        <div className="inner">
          <form className="block" onSubmit={this.onSubmit.bind(this)}>
            <h3>Access stored online file using your credentials:</h3>
            <FormControl>
              <RadioGroup
                options={versionRadioOptions}
                value={this.state.version}
                onChange={this.onVersionChange.bind(this)}
              />
            </FormControl>
            <FormControl>
              <InputGroup
                icon={faEnvelope}
                name="username"
                placeholder="Username / Email"
                onChange={this.onInputChange.bind(this, 'username')}
              />
            </FormControl>
            <FormControl>
              <InputGroup
                icon={faUser}
                name="userId"
                placeholder="User ID"
                onChange={this.onInputChange.bind(this, 'userId')}
              />
            </FormControl>
            <FormControl>
              <InputGroup
                icon={faLock}
                type="password"
                name="password"
                placeholder="Password"
                onChange={this.onInputChange.bind(this, 'password')}
              />
            </FormControl>
            <FormControl>
              <div className="server-error" style={{display: ui.error ? 'block' : 'none'}}>
                <span>{ui.error}</span>
              </div>
            </FormControl>
            <Button type="submit">Log In</Button>
          </form>
          <div className="block">
            <h4>Alternatively upload offline file:</h4>
            <FileLoader />
          </div>
        </div>
        {ui.loading && <Spinner />}
      </div>
    );
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.login({ ...this.state });
  }
}

Login.propTypes = {
  ui: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
};
