const SET_NOTIFICATION = 'notification/SET';

export default (state = null, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return action.payload;
    default:
      return state;
  }
}

export const addNotification = (title, options) => {
  return { type: SET_NOTIFICATION, payload: { title, options }};
};

export const getNotification = (state) => {
  return state.ui.notification;
};
