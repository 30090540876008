import { NS as UI_NS } from '../constant';
import {LOGIN} from '../../user/reducer';

const NS = `login`;
const REQUEST_LOGIN = `${UI_NS}/${NS}/REQUEST_LOGIN`;
const LOGIN_ERROR = `${UI_NS}/${NS}/LOGIN_ERROR`;

const initState = { loading: false, error: null };

export default function (state = initState, action) {
  switch (action.type) {
    case REQUEST_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case LOGIN:
      return initState;
    default:
      return state;
  }
}

export function requestLogin() {
  return { type: REQUEST_LOGIN };
}

export function loginError(error) {
  return { type: LOGIN_ERROR, payload: error };
}
