import React, {Component} from 'react';
import tableDataShape from '../tableDataShape';
import './tableBody.css';
import Row from './Row';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export default class TableBody extends Component {
  render() {
    const rows = this.props.data.values.map(row => {
      const id = row.id.value;
      const classes = classnames('mg-table-body-row', {
        hovered: id === this.props.hoveredId,
      });
      return (
        <tr
          className={classes}
          key={id}
          onMouseEnter={this.props.onHover.bind(this, id)}
          onMouseLeave={this.props.onHover.bind(this, null)}
          onClick={this.props.onClick.bind(this, id)}
        >
          <Row headers={this.props.data.headers} row={row} />
        </tr>
      );
    });
    return (
      <tbody className="mg-table-body">
        {rows}
      </tbody>
    );
  }
}

TableBody.propTypes = {
  data: tableDataShape.isRequired,
  hoveredId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onHover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
