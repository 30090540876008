import {Component} from 'react';
import PropTypes from 'prop-types';
import {CommutesDrawer} from './commutes-drawer';
import * as _ from 'lodash';
import {createGroup} from '../../shared/map/utils';
import {PlacesDrawer} from './places-drawer';
import {RoutesDrawer} from './routes-drawer';

export default class AnalysisMap extends Component {
  drawers;
  group = createGroup();

  componentDidUpdate(prevProps) {
    const { map } = this.props;
    if (!map) {
      return;
    }

    let isInit = false;
    if (!this.drawers) {
      this.initDrawers(map);
      isInit = true;
    }

    const drawer = this.drawers[this.props.state.selectedMGObject.type];

    if (isInit || !_.isEqual(this.props.state.cloudSyncData, prevProps.state.cloudSyncData) ||
      !_.isEqual(this.props.state.selectedMGObject, prevProps.state.selectedMGObject) ||
      !_.isEqual(this.props.state.filters, prevProps.state.filters)) {

      this.group.removeAll();
      if (this.props.state.cloudSyncData) {
        drawer.draw(this.props.state);

        const bbox = this.group.getBoundingBox();
        if (bbox) {
          map.getViewModel().setLookAtData({bounds: bbox}, true);
        }
      }
    }

    drawer.update(this.props.state);
  }

  componentWillUnmount() {
    this.props.map.removeObject(this.group);
  }

  render() {
    return null;
  }

  initDrawers(map) {
    map.addObject(this.group);
    this.drawers = {};
    this.drawers.commutes = new CommutesDrawer(map, this.group, this.props.setHoveredCommute, this.props.selectCommute);
    this.drawers.places =
      new PlacesDrawer(map, this.group, this.drawers, this.props.setHoveredPlace, this.props.selectPlace);
    this.drawers.routes = new RoutesDrawer(map, this.group, this.props.setHoveredRoute, this.props.selectRoute);
  }
}

AnalysisMap.propTypes = {
  state: PropTypes.object.isRequired,
  selectPlace: PropTypes.func.isRequired,
  selectRoute: PropTypes.func.isRequired,
  selectCommute: PropTypes.func.isRequired,
  setHoveredCommute: PropTypes.func.isRequired,
  setHoveredPlace: PropTypes.func.isRequired,
  setHoveredRoute: PropTypes.func.isRequired,
};
