import {getAddress} from '../../../utils/address';
import Rx from 'rxjs/Rx';

export function coordsParser(point) {
  return `lat:${point.lat}\nlng:${point.lng}`;
}

export function addressParser(point) {
  if (point.address) {
    return Rx.Observable.of(point.address);
  }

  return getAddress(`${point.lat},${point.lng}`);
}

export function numberParser(number) {
  return +number.toFixed(3);
}
