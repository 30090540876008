import React, { Component } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import './style.css';

const verticalMargin = 4;
const horizontalMargin = 2.45;
const weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export default class Timetable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    hoveredItem: PropTypes.number,
    uiZoom: PropTypes.number.isRequired,
  };

  render() {
    const { data, hoveredItem, uiZoom } = this.props;

    // Emulating rem size by multiplying size with uiZoom because firefox does not support rem inside svg
    return (
      <div className="mg-timetable">
        <svg className="visualization-svg" style={{ height: (verticalMargin * 6 + 4.6) * uiZoom + 'px'}}>
          {_.range(7).map(weekday => <g key={weekday} className="day-label">
            <circle r={1.3 * uiZoom + "px"} cx={1.5 * uiZoom + "px"} cy={(2.3 + weekday * verticalMargin) * uiZoom + "px"} />
            <text x={1.5 * uiZoom + "px"} y={(2.3 + weekday * verticalMargin) * uiZoom + "px"} dy={.5 * uiZoom + "px"}>{weekdays[weekday]}</text>
          </g>)}
          <g className="lines-wrapper">
            {_.range(7).map(weekday => <line
              key={weekday}
              x1={4.1 * uiZoom + "px"}
              y1={(2.3 + weekday * verticalMargin) * uiZoom + "px"}
              x2={(4.1 + horizontalMargin * 23) * uiZoom + "px"}
              y2={(2.3 + weekday * verticalMargin) * uiZoom + "px"}
            />)}
            {_.range(24).map(hour =>
              <line
                key={hour + 7}
                x1={(4.1 + hour * horizontalMargin) * uiZoom + "px"}
                y1={2.3 * uiZoom + "px"}
                x2={(4.1 + hour * horizontalMargin) * uiZoom + "px"}
                y2={(6 * verticalMargin + 2.3) * uiZoom + "px"}
              />
            )}
          </g>
          <g className="hours-wrapper">
            {_.range(24).map(hour => <text
              key={hour}
              className="hour"
              x={(4.1 + hour * horizontalMargin) * uiZoom + "px"}
              y={uiZoom + "px"}
            >{hour}</text>)}
          </g>
          <g className="time-markers-wrapper">
            {_.range(7).map(weekday => _.range(24).map(hour => {
              const item = _.get(data, `[${weekday}][${hour}]`);
              return item ?
                <circle
                  key={weekday + '-' + hour}
                  className={'time-marker' + (item.keys.indexOf(hoveredItem) >= 0 ? ' hovered' : '')}
                  r={.75 * uiZoom + "px"}
                  cx={(4.1 + hour * horizontalMargin) * uiZoom + "px"}
                  cy={(2.3 + weekday * verticalMargin) * uiZoom + "px"}
                /> : null
              }
            ))};
          </g>
        </svg>
      </div>
    );
  }
}
