export function parseCommutes(dbCommutes, places) {
  const placesCommutesIndex = {};
  const commutes = dbCommutes.reduce((red, dbCommute) => {
    const commuteId = dbCommute[0];
    if (!red[commuteId]) {
      const startPlaceId = dbCommute[1];
      const endPlaceId = dbCommute[2];
      places[startPlaceId].commutesCount++;
      places[endPlaceId].commutesCount++;
      const commute = {
        id: dbCommute[0],
        startPlaceId,
        endPlaceId,
        creationTime: dbCommute[3],
        raw: null,
        start: places[dbCommute[1]].point,
        end: places[dbCommute[2]].point,
        scoreDistribution: [],
        score: 0,
        routesCount: 0,
      };
      red[commuteId] = commute;
      if (!placesCommutesIndex[commute.startPlaceId]) {
        placesCommutesIndex[commute.startPlaceId] = {
          start: [],
          end: [],
        };
      }
      placesCommutesIndex[commute.startPlaceId].start.push(commute.id);

      if (!placesCommutesIndex[commute.endPlaceId]) {
        placesCommutesIndex[commute.endPlaceId] = {
          start: [],
          end: [],
        };
      }
      placesCommutesIndex[commute.endPlaceId].end.push(commute.id);
    }
    const TimeBucketId = dbCommute[7];
    const score = Math.exp(dbCommute[6]);
    const weekday = dbCommute[5];

    const hour = TimeBucketId % 2 ? TimeBucketId - 1 : TimeBucketId;

    red[commuteId].score += score;
    if (!red[commuteId].scoreDistribution[weekday]) {
      red[commuteId].scoreDistribution[weekday] = [];
    }
    if (!red[commuteId].scoreDistribution[weekday][hour]) {
      red[commuteId].scoreDistribution[weekday][hour] = [];
    }
    red[commuteId].scoreDistribution[weekday][hour].push({
      score,
      hour: TimeBucketId,
    });
    return red;
  }, {});

  return { commutes, placesCommutesIndex };
}
