import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export default class NoCommutesMessage extends Component {
  static propTypes = {
    numOfTracks: PropTypes.number.isRequired,
    firstRecording: PropTypes.number.isRequired,
    lastRecording: PropTypes.number.isRequired,
  };

  render() {
    return (
      <div style={{color: 'red', textAlign: 'center', marginTop: 10}}>
        No commutes have been recorded in this DB. There are {this.props.numOfTracks} raw recorded tracks
        between {moment(this.props.firstRecording).format(DATE_FORMAT)} and {moment(this.props.lastRecording).format(DATE_FORMAT)}
      </div>
    );
  }
}
