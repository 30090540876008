import {PROXY_URL} from '../../config';
import {loginError, requestLogin} from '../ui/login/reducer';
import {getInitState, updateLocalStorage} from '../../utils/localStorage';
import {mgFetchPost} from '../../utils/fetch';

const NS = 'user';

export const LOGIN = `${NS}/LOGIN`;
export const LOGOUT = `${NS}/LOGOUT`;
const LOCAL_STORAGE_USER_KEY = 'user';

const initState = getInitState(LOCAL_STORAGE_USER_KEY);

export default function (state = initState, action) {
  switch (action.type) {
    case LOGIN:
      return action.payload;
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export function login(user = {}) {
  return async (dispatch) => {
    const { username, password, version, userId } = user;

    dispatch(requestLogin());

    let res;
    try {
      res = await mgFetchPost(`${PROXY_URL}/mg-auth`, { username, password, version });
    } catch (e) {
      dispatch(loginError(e.data ? e.data.error_description : e.message));
      return;
    }

    const userRes = {
      username,
      password,
      token: res.access_token,
      expires: new Date().getTime() + (res.expires_in * 1000),
      version,
      userId,
    };
    updateLocalStorage(LOCAL_STORAGE_USER_KEY, userRes);
    dispatch({ type: LOGIN, payload: userRes });
  };
}

export function logout() {
  updateLocalStorage(LOCAL_STORAGE_USER_KEY, '{}');
  return { type: LOGOUT };
}
