import * as _ from 'lodash';

export function totalScoreParser (learnedDatas) {
  if (!learnedDatas) {
    return null;
  }

  return _.reduce(learnedDatas, (result, learnedData) => {
    if (!learnedData.ScoreDistributions) {
      return result;
    }

    return result + _.reduce(learnedData.ScoreDistributions, (scoreDistributionsResult, scoreDistribution) => {
      if (!scoreDistribution.Entries) {
        return scoreDistributionsResult;
      }

      return scoreDistributionsResult + _.reduce(scoreDistribution.Entries, (entriesResult, entry) => {
        return entriesResult + Math.exp(entry.Score);
      }, null);
    }, null);
  }, null);
}
