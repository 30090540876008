import {createStartMarker} from '../../shared/map/utils';
import {Drawer} from '../../shared/map/utils/drawer';
import {
  COMMUTE_LINE_OPTIONS, createCircle, createCommuteRoute, createFinishMarker, createMarker, createRoute,
  getDefaultIcon
} from "../../shared/map/utils/index";
import { numToChar } from "../../../utils/index";

export class PredictionsDrawer extends Drawer {
  constructor({ map, group, onHoverLineCb, onHoverRouteCb, onClickLineCb }) {
    super(map, group);

    this.onHoverLineCb = onHoverLineCb;
    this.onHoverRouteCb = onHoverRouteCb;
    this.onClickLineCb = onClickLineCb;
    this.marker = null;
    this.markerObjs = [];
    this.lines = [];
    this.routes = [];
  }

  draw(state) {
    if (this.marker) {
      this.group.removeObject(this.marker);
      this.marker = null;
    }
    if (state.predictions.formData.coords) {
      this.drawSelectedMarker(state);
    }

    if (this.markerObjs.length) {
      this.group.removeObjects(this.markerObjs);
      this.markerObjs = [];
    }

    if (this.routes.length) {
      this.group.removeObjects(this.routes);
      this.routes = [];
    }

    if (state.predictions.response.destinations && state.predictions.selectedPrediction === null) { // Draw destinations
      if (this.lines.length) {
        this.group.removeObjects(this.lines);
        this.lines = [];
      }

      this.drawDestinationLines(state);
      this.drawDestinationMarkers(state);
    } else if (state.predictions.selectedPrediction !== null) { // Draw routes
      if (this.lines.length) {
        this.group.removeObjects(this.lines);
        this.lines = [];
      }

      this.drawDestinationMarkers(state, false);
      this.drawRoutePolylines(state);
      this.drawRouteMarkers(state);
      this.drawFinishMarker(state);
    } else if (state.cloudSyncData) {
      this.drawPlaces(state);
    }
  }

  // Draw objects

  drawSelectedMarker(state) {
    this.marker = createStartMarker(state.predictions.formData.coords);
    this.group.addObject(this.marker);
  }

  drawPlaces(state) {
    Object.keys(state.cloudSyncData.places).forEach(key => {
      const place = state.cloudSyncData.places[key];
      const marker = createMarker(place.point);
      this.group.addObject(marker);
      this.markerObjs.push(marker);
    });
  }

  drawDestinationLines(state) {
    state.predictions.response.destinations._embedded.prediction.forEach((prediction, index) => {
      const coords = prediction.destination.Position.Coordinates;
      const point = {
        lat: coords.latitude,
        lng: coords.longitude,
      };

      const route = createCommuteRoute({
        start: state.predictions.formData.coords,
        end: point,
      });
      route.addEventListener('pointerenter', () => this.onDestinationPointerEnter(index));
      route.addEventListener('pointerleave', () => this.onDestinationPointerLeave());
      route.addEventListener('tap', () => this.onDestinationClick(index));
      this.group.addObject(route);
      this.lines.push(route);
    });
  }

  drawDestinationMarkers(state, showAccuracy = true) {
    state.predictions.response.destinations._embedded.prediction.forEach((prediction, index) => {
      const coords = prediction.destination.Position.Coordinates;
      const point = {
        lat: coords.latitude,
        lng: coords.longitude,
      };

      const marker = createMarker(point, {
        icon: getDefaultIcon(index + 1),
      });
      marker.addEventListener('pointerenter', () => this.onDestinationPointerEnter(index));
      marker.addEventListener('pointerleave', () => this.onDestinationPointerLeave());
      marker.addEventListener('tap', () => this.onDestinationClick(index));
      this.group.addObject(marker);
      this.markerObjs.push(marker);

      if (showAccuracy) {
        const circle = createCircle(point, prediction.destination.Position.AccuracyMeters);
        this.group.addObject(circle);
        this.markerObjs.push(circle);
      }
    });
  }

  drawRoutePolylines(state) {
    state.predictions.response.routes._embedded.prediction[state.predictions.selectedPrediction].routes.forEach((routeObj, index) => {
      const shape = [];
      routeObj.route.WaypointsGroups.forEach(waypointsGroup => {
        waypointsGroup.Waypoints.forEach(waypoint => {
          const latlng = {
            lat: waypoint.Position.latitude,
            lng: waypoint.Position.longitude,
          };
          shape.push(latlng);
        });
      });
      const route = createRoute(shape, routeObj.Identifier);
      route.addEventListener('pointerenter', () => this.onRoutePointerEnter(index));
      route.addEventListener('pointerleave', () => this.onRoutePointerLeave());
      this.group.addObject(route);
      this.routes.push(route);
    });
  }

  drawRouteMarkers(state) {
    state.predictions.response.routes._embedded.prediction[state.predictions.selectedPrediction].routes.forEach((routeObj, index) => {
        const coords = routeObj.route.WaypointsGroups[0].Waypoints[0].Position;
        const point = {
          lat: coords.latitude,
          lng: coords.longitude,
        };
        const marker = createMarker(point, {
          icon: getDefaultIcon(numToChar(index), '#018f8b'),
        });
        marker.addEventListener('pointerenter', () => this.onRoutePointerEnter(index));
        marker.addEventListener('pointerleave', () => this.onRoutePointerLeave());
        this.group.addObject(marker);
        this.markerObjs.push(marker);
    });
  }

  drawFinishMarker(state) {
    const coords = state.predictions.response.destinations._embedded
      .prediction[state.predictions.selectedPrediction].destination.Position.Coordinates;
    const point = {
      lat: coords.latitude,
      lng: coords.longitude,
    };
    const marker = createFinishMarker(point);
    this.group.addObject(marker);
    this.markerObjs.push(marker);
  }


  // Destinations

  onDestinationPointerEnter(index) {
    this.map.getElement().style.cursor = 'pointer';
    this.onHoverLineCb(index);
  }

  onDestinationPointerLeave() {
    this.map.getElement().style.cursor = 'auto';
    this.onHoverLineCb(null);
  }

  onDestinationClick(index) {
    this.onClickLineCb(index);
  }

  setHoveredLine(id) {
    this.lines[id] && this.lines[id].setStyle({
      lineDash: [],
      strokeColor: 'orange',
      fillColor: 'orange',
      lineWidth: 4,
    })
  }

  setNotHoveredLine(id) {
    this.lines[id] && this.lines[id].setStyle(COMMUTE_LINE_OPTIONS.style);
  }

  // Routes

  onRoutePointerEnter(index) {
    this.map.getElement().style.cursor = 'pointer';
    this.onHoverRouteCb(index);
  }

  onRoutePointerLeave() {
    this.map.getElement().style.cursor = 'auto';
    this.onHoverRouteCb(null);
  }

  setHoveredRoute(id) {
    this.routes[id] && this.routes[id].setStyle({
      lineDash: [],
      strokeColor: 'orange',
      fillColor: 'orange',
      lineWidth: 4,
    })
  }

  setNotHoveredRoute(id) {
    this.routes[id] && this.routes[id].setStyle({}.style);
  }
}
