import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './tableHead.css';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faSort } from '@fortawesome/fontawesome-free-solid';

export default class TableHead extends Component {
  render() {
    const { headers, sort } = this.props;
    const headerEls = headers.map(header => {
      const isSort = sort.column === header;
      const classes = classnames('mg-table-header-item', {
        'mg-table-header-item-sorting': isSort,
      });

      const icon = isSort ? (isSort && sort.desc ? faSortUp : faSortDown) : faSort;
      return (
        <th
          key={header}
          className={classes}
          onClick={this.props.onChangeSort.bind(this, header)}
        >{header} <FontAwesomeIcon icon={icon} /></th>
      );
    });
    return (
      <thead>
        <tr className="mg-table-header">
          {headerEls}
        </tr>
      </thead>
    );
  }
}

TableHead.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  sort: PropTypes.shape({
    column: PropTypes.string,
    desc: PropTypes.bool.isRequired,
  }).isRequired,
  onChangeSort: PropTypes.func.isRequired,
};
