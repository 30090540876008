import {LOGOUT} from '../../user/reducer';

const NS = 'predictions/hoveredPredictionRoute';
const SET = `${NS}/SET`;

export default function hoveredPredictionRoute(state = null, action) {
  switch (action.type) {
    case SET:
      return action.payload;
    case LOGOUT:
      return null;
    default:
      return state;
  }
}

export function setHoveredPredictionRoute(routeId) {
  return { type: SET, payload: routeId };
}
