import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Cell extends Component {
  constructor(props) {
    super(props);

    if (props.async) {
      this.state = {
        value: 'Loading...',
      };
    } else {
      const value = props.parser ? props.parser(props.value) : props.value;
      this.state = { value };
    }
  }

  componentDidMount() {
    const { parser, value, async } = this.props;
    if (async) {
      parser(value).subscribe(address => {
        this.setState({ value: address });
      });
    }
  }

  render() {
    return (
      <td className="mg-table-body-cell">{this.state.value}</td>
    );
  }
}

Cell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]).isRequired,
  parser: PropTypes.func,
  async: PropTypes.bool,
};
