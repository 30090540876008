import {addressParser} from './common-parsers';

export const ROUTE_PARSERS = {
  headers: [
    'Start',
    'End',
    'Duration',
    'Distance',
    'Personal Traffic Delay',
  ],
  parsers: {
    'Start': { path: 'start', parser: addressParser, async: true },
    'End': { path: 'end', parser: addressParser, async: true },
    'Duration': { path: 'duration' },
    'Distance': { path: 'dist' },
    'Personal Traffic Delay': { path: 'trafficDelay' },
  },
};
