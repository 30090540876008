import React from 'react';
import PropTypes from 'prop-types';
import './subscriptionsLeft.scss';
import Toggle from 'react-toggle';
import SubscriptionList from './SubscriptionList';

class SubscriptionsLeft extends React.Component {
  static propTypes = {
    host: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    subscriptions: PropTypes.array,
    isActive: PropTypes.bool,
    setHost: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    toggleService: PropTypes.func.isRequired,
  };

  onChangeHost = (e) => {
    this.props.setHost(e.target.value);
  };

  onToggleService = () => {
    const { isActive, toggleService } = this.props;

    toggleService(!isActive);
  };

  render() {
    const { host, isActive, reload, subscriptions, children, isLoading } = this.props;

    return (
      <div className="mg-subscriptions-left">
        <div>
          <label>
            Host
            <lui-textfield>
              <input
                type="text"
                placeholder="Host"
                onChange={this.onChangeHost}
                value={host}
              />
            </lui-textfield>
          </label>
          <lui-button onClick={reload}>Reload service and subscriptions</lui-button>
        </div>
        {isActive === null && <div>Traffic notifications service not loaded</div>}
        <div>
          <label>
            <Toggle onChange={this.onToggleService} checked={!!isActive} disabled={isActive === null} />
            <span>{isActive ? 'Deactivate' : 'Activate'} traffic notifications service</span>
          </label>
        </div>
        <SubscriptionList subscriptions={subscriptions} />
        {children}
        {isLoading && <lui-spinner className="lui-large" />}
      </div>
    );
  }
}

export default SubscriptionsLeft;
