import { SET_DESTINATIONS_DATA } from "../response/reducer";
import {LOGOUT} from '../../user/reducer';

const NS = 'predictions/selectedPrediction';
const SET = `${NS}/SET`;

export default function selectedPrediction(state = null, action) {
  switch (action.type) {
    case SET:
      return action.payload;
    case SET_DESTINATIONS_DATA:
      return null;
    case LOGOUT:
      return null;
    default:
      return state;
  }
}

export function setSelectedPrediction(predictionId) {
  return { type: SET, payload: predictionId };
}
