import user from './user/reducer';
import ui from './ui';
import file from './file/reducer';
import filters from './filters/reducer';
import cloudSyncData from './cloudSyncData/reducer';
import selectedMGObject from './selectedMGObject/reducer'
import hoveredCommute from './hoveredCommute/reducer';
import hoveredPlace from './hoveredPlace/reducer';
import hoveredRoute from './hoveredRoute/reducer';
import predictions from './predictions/reducer';
////////////////
import pushNotification from './pushNotification/reducer';
import subscriptions from './subscriptions';
//////////

export const reducers = {
  user,
  ui,
  cloudSyncData,
  file,
  filters,
  selectedMGObject,
  hoveredCommute,
  hoveredRoute,
  hoveredPlace,
  predictions,
////////////////
  pushNotification,
  subscriptions,
//////////
};
