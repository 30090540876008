import React, {Component} from 'react';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import './slider.css';

export default class Slider extends Component {
  render() {
    return (
      <Range {...this.props} />
    );
  }
}
