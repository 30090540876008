import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as _ from 'lodash';

import './debug.css';
import { proxyFetch, defaultUrl, allDataBody } from "../../utils/fetch";
import { setCloudSyncData } from "../../state/cloudSyncData/reducer";
import Json from '../shared/json/Json';
import { getUserIdByUser } from '../../state/user/getters';

class Debug extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      response: null,
      isBodyValid: true,
    };
    this.bodyChanged = _.debounce(this.bodyChanged.bind(this), 100)
  }

  bodyChanged() {
    try {
      JSON.parse(this.bodyEl.value);
      this.setState({ isBodyValid: true })
    } catch (err) {
      this.setState({ isBodyValid: false })
    }
  }

  async request(e) {
    e.preventDefault();
    const url = this.urlEl.value;
    const body = JSON.parse(this.bodyEl.value);
    let response;
    const responseObj = await proxyFetch(this.props.user.token, url, body);
    const responseAsText = await responseObj.text();
    try {
      response = JSON.parse(responseAsText);
      this.props.setCloudSyncData(response);
    } catch (e) {
      response = { errorMessage: responseAsText };
    }
    this.setState({ response })
  }

  async removeAllData(e) {
    if (!window.confirm('WARNING! You will remove all data associated with this account')) {
      return;
    }
    const url = this.urlEl.value;
    let response;
    const responseObj = await proxyFetch(this.props.user.token, url, null, 'DELETE');
    const responseAsText = await responseObj.text();
    try {
      response = JSON.parse(responseAsText);
      this.props.setCloudSyncData(response);
    } catch (e) {
      response = { errorMessage: e.message };
    }
    this.setState({ response })
  }

  render() {
    const body = { ...allDataBody };
    body.userid = getUserIdByUser(this.props.user);

    return <div className="mg-debug">
      <div className="left">
        <form onSubmit={e => this.request(e)}>
          <div className="form-group">
            <label>Url:</label>
            <input name="url" defaultValue={defaultUrl} ref={el => { this.urlEl = el; }} />
          </div>
          <div className="form-group">
            <label>Body:</label>
            <textarea
              className={this.state.isBodyValid ? '' : 'invalid'}
              name="body"
              defaultValue={JSON.stringify(body, null, 2)}
              ref={el => { this.bodyEl = el; }}
              onChange={this.bodyChanged}
            />
          </div>
          <button className="mg-button" disabled={!this.state.isBodyValid}>Request</button>
        </form>
        <button className="mg-button delete" onClick={e => this.removeAllData(e)}>Remove all data</button>
      </div>
      <div className="right">
        <Json data={this.state.response || this.props.cloudSyncDataJson} />
      </div>
    </div>;
  }
}

const mapStateToProps = state => ({
  cloudSyncDataJson: state.cloudSyncData ? state.cloudSyncData.rawData : {},
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setCloudSyncData: bindActionCreators(setCloudSyncData, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Debug));
