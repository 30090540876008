import { proxyFetch } from '../../../utils/fetch';
import { getUserToken } from '../../user/getters';
import { setSubscrIsLoading } from '../ui/reducer';
import { errorNotification } from '../helpers';

const SET_RES = 'subscriptions/debug/SET_RES';
const SET_URL = 'subscriptions/debug/SET_URL';
const SET_BODY = 'subscriptions/debug/SET_BODY';

const initState = {
  url: 'https://notifications.pnav-set.sit.cc.api.here.com/subscriptions/traffic_notifications',
  body: '',
  res: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case SET_BODY:
      return {
        ...state,
        body: action.payload
      };
    case SET_URL:
      return {
        ...state,
        url: action.payload,
      };
    case SET_RES:
      return {
        ...state,
        res: action.payload,
      };
    default:
      return state;
  }
}

export const setSubscrDebugUrl = (url) => ({ type: SET_URL, payload: url });
export const setSubscrDebugBody = (body) => ({ type: SET_BODY, payload: body });

export const debugSubscriptions = (method) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(setSubscrIsLoading('debug', true));

      const url = getDebugUrl(state);
      const body = getDebugBody(state);
      const token = getUserToken(state);
      const res = await proxyFetch(token, url, body, method);
      if (res.status >= 400) {
        await errorNotification(dispatch, res);
      } else {
        dispatch({ type: SET_RES, payload: await res.json()});
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setSubscrIsLoading('debug', false));
    }
  };
};

export const getDebugUrl = (state) => state.subscriptions.debug.url;
export const getDebugBody = (state) => state.subscriptions.debug.body;
export const getDebugRes = (state) => state.subscriptions.debug.res;
