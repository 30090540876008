import * as _ from 'lodash';
import { totalScoreParser } from './common-parsers';

export function placeParser(rawPlace, rawDataById) {
  const placeId = _.get(rawPlace, 'objectData.Model.Identifier');
  return {
    id: _.get(rawPlace, 'objectData.Model.Identifier'),
    point: coordsParser(rawPlace),
    commutesCount: commutesCountParser(placeId, rawDataById),
    creationTime: _.get(rawPlace, 'objectData.Model.OnDeviceCreationTimeMs'),
    raw: rawPlace,
    score: totalScoreParser(_.get(rawPlace, 'objectData.LearnedDatas'))
  };
}

function commutesCountParser(objectIdentifier, data) {
  return Object.keys(data).filter(key => {
    const obj = data[key];
    return _.get(obj, 'objectData.ModelType') === 'Transition' && (
      _.get(obj, 'objectData.Model.StartPlace.ObjectIdentifier') === objectIdentifier ||
      _.get(obj, 'objectData.Model.EndPlace.ObjectIdentifier') === objectIdentifier
    );
  }).length;
}

function coordsParser(rawPlace) {
  const coords = _.get(rawPlace, 'objectData.Model.Position.Coordinates', {});
  return {
    lat: coords.latitude,
    lng: coords.longitude,
  };
}
