import {createSelector} from 'reselect';

export const getUser = (state) => state.user;

export const getUserToken = (state) => getUser(state).token;

export const getIsLoggedIn = createSelector(
  getUser,
  (user) => user && user.expires > new Date().getTime(),
);

export const getPseudoUserId = createSelector(
  getUserToken,
  (token) => JSON.parse(atob(token.split('.')[1])).sub,
);

export const getUserIdByUser = (user) => user.userId;
