import React from 'react';
import Left from '../shared/left/Left';
import PropTypes from 'prop-types';
import LeafletMap from '../shared/leafletMap/LeafletMap';
import SubscriptionsLeft from './SubscriptionsLeft';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSubscriptionsHost, setHost } from '../../state/subscriptions/host/reducer';
import { getIsServiceActive, loadService, toggleService } from '../../state/subscriptions/isActive/reducer';
import AddSubscription from './AddSubscription';
import {
  addSubscription, deleteSubscription,
  getSubscriptionsList,
  loadSubscriptions
} from '../../state/subscriptions/subscriptions/reducer';
import SubscriptionMarkers from './SubscriptionMarkers';
import { featureGroup, marker } from 'leaflet';
import DebugSubscriptions from './DebugSubscriptions';
import {
  debugSubscriptions, getDebugBody, getDebugRes,
  getDebugUrl,
  setSubscrDebugBody,
  setSubscrDebugUrl
} from '../../state/subscriptions/debug/reducer';
import { getSubscrIsLoading } from '../../state/subscriptions/ui/reducer';

class Subscriptions extends React.Component {
  static propTypes = {
    host: PropTypes.string.isRequired,
    debugUrl: PropTypes.string.isRequired,
    debugBody: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    debugRes: PropTypes.object,
    isActive: PropTypes.bool,
    subscriptions: PropTypes.array,
    loadService: PropTypes.func.isRequired,
    toggleService: PropTypes.func.isRequired,
    loadSubscriptions: PropTypes.func.isRequired,
    addSubscription: PropTypes.func.isRequired,
    deleteSubscription: PropTypes.func.isRequired,
    setSubscrDebugBody: PropTypes.func.isRequired,
    setSubscrDebugUrl: PropTypes.func.isRequired,
    debugSubscriptions: PropTypes.func.isRequired,
  };

  state = {
    contextPos: null,
    latLng: null,
  };

  componentDidMount = () => {
    this.props.loadService();
    this.props.loadSubscriptions();
  };

  onMapContextMenu = (e) => {
    this.setState({
      contextPos: e.containerPoint,
      latLng: e.latlng,
    });
    document.addEventListener('click', this.onCloseContextMenu);
  };

  onCloseContextMenu = () => {
    this.setState({
      contextPos: null,
      latLng: null,
    });
    document.removeEventListener('click', this.onCloseContextMenu);
  };

  render() {
    const { host, setHost, isActive, toggleService, addSubscription, deleteSubscription, subscriptions,
            setSubscrDebugBody, setSubscrDebugUrl, debugSubscriptions, debugUrl, debugBody, debugRes,
            isLoading } = this.props;
    const { contextPos, latLng } = this.state;

    let center;
    if (subscriptions && subscriptions.length > 0) {
      const markers = subscriptions.map((subscription) =>
        marker([subscription.location.lat, subscription.location.lon])
      );
      center = featureGroup(markers).getBoundingBox().getCenter();
    } else {
      center = [52.520008, 13.404954]; // Berlin
    }

    return (
      <lui-contextchromelayer-theme>
        <Left>
          <SubscriptionsLeft
            host={host}
            setHost={setHost}
            isActive={isActive}
            reload={this.componentDidMount}
            toggleService={toggleService}
            subscriptions={subscriptions}
            isLoading={isLoading}
          >
            <DebugSubscriptions
              debug={debugSubscriptions}
              setBody={setSubscrDebugBody}
              setUrl={setSubscrDebugUrl}
              url={debugUrl}
              body={debugBody}
              res={debugRes}
            />
          </SubscriptionsLeft>
        </Left>
        <LeafletMap center={center} zoom={13} onContextMenu={this.onMapContextMenu}>
          <SubscriptionMarkers deleteSubscription={deleteSubscription} subscriptions={subscriptions} />
          {contextPos && <AddSubscription latLng={latLng} pos={contextPos} onAdd={addSubscription} />}
        </LeafletMap>
      </lui-contextchromelayer-theme>
    );
  }
}

const mapStateToProps = (state) => ({
  host: getSubscriptionsHost(state),
  isActive: getIsServiceActive(state),
  subscriptions: getSubscriptionsList(state),
  debugUrl: getDebugUrl(state),
  debugBody: getDebugBody(state),
  debugRes: getDebugRes(state),
  isLoading: getSubscrIsLoading(state),
});

const mapDispatchToProps = {
  setHost,
  loadService,
  toggleService,
  loadSubscriptions,
  addSubscription,
  deleteSubscription,
  setSubscrDebugBody,
  setSubscrDebugUrl,
  debugSubscriptions,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Subscriptions));
