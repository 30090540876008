export const PLACES_QUERY = `
SELECT
  place_id,
  creation_timestamp,
  location_latitude,
  location_longitude,
  name,
  score
FROM places;
`;

export const COMMUTES_QUERY = `
SELECT
  cm.commute_id,
  cm.start_place_id,
  cm.end_place_id,
  cm.creation_time,
  ct.hour,
  ct.weekday,
  ct.count,
  ct.hour
FROM commutes AS cm LEFT JOIN departure_timetables AS ct ON cm.commute_id = ct.commute_id;
`;

export const ROUTES_QUERY = `
SELECT
  tr.track_id,
  b.last_drive_time,
  tr.commute_id,
  rg.road_element_id,
  rg.latitude,
  rg.longitude,
  te.against_geometry,
  tr.duration,
  re.length,
  tr.start_latitude,
  tr.start_longitude,
  tr.end_latitude,
  tr.end_longitude,
  te.traffic_delay,
  b.score,
  b.deleted,
  te.road_element_id
FROM buckets AS b
  JOIN tracks AS tr ON b.bucket_id = tr.bucket_id
  JOIN track_elements AS te ON tr.track_id = te.track_id
  JOIN road_elements AS re ON te.road_element_id = re.road_element_id
  JOIN road_elements_geometries AS rg ON re.road_element_id = rg.road_element_id
WHERE tr.type = 2
ORDER BY tr.track_id, te.element_index;
`;

export const NUM_OF_TRACKS_QUERY = 'SELECT count(*) FROM tracks WHERE type = 1;';

export const FIRST_RECORDING_QUERY = 'SELECT MIN(start_timestamp) FROM tracks WHERE type=1;';

export const LAST_RECORDING_QUERY = 'SELECT MAX(start_timestamp) FROM tracks WHERE type=1;';
