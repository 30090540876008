import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
  COMMUTES_QUERY, FIRST_RECORDING_QUERY, LAST_RECORDING_QUERY, NUM_OF_TRACKS_QUERY, PLACES_QUERY,
  ROUTES_QUERY,
} from './db.queries';
import './fileLoader.css';
import * as _ from 'lodash';
import {uploadFile} from '../../../state/file/reducer';
import NoCommutesMessage from './NoCommutesMessage';

class FileLoader extends Component {
  state = {
    isDragOver: false,
    noCommutes: null,
  };

  dragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({isDragOver: true});
  }

  dragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({isDragOver: false});
  }

  drop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.parseFile(e.dataTransfer.files[0]);
    this.setState({isDragOver: false});
  }

  inputChange(e) {
    this.parseFile(e.target.files[0]);
    e.target.value = '';
  }

  parseFile(file) {
    const r = new FileReader();
    r.onload = () => {
      try {
        const Uints = new Uint8Array(r.result);
        const db = new window.SQL.Database(Uints);

        const dbPlaces = _.get(db.exec(PLACES_QUERY), '0.values', []);
        const dbCommutes = _.get(db.exec(COMMUTES_QUERY), '0.values', []);
        const dbRoutes = _.get(db.exec(ROUTES_QUERY),'0.values', []);

        if (dbCommutes.length === 0) {
          const numOfTracks = _.get(db.exec(NUM_OF_TRACKS_QUERY), '0.values.0.0', 0);
          const firstRecording = _.get(db.exec(FIRST_RECORDING_QUERY), '0.values.0.0', 0);
          const lastRecording = _.get(db.exec(LAST_RECORDING_QUERY), '0.values.0.0', 0);
          this.setState({
            noCommutes: { numOfTracks, firstRecording, lastRecording },
          });
        } else {
          this.props.uploadFile({
            file: {
              name: file.name,
              // transform bytes to kilobytes
              size: file.size / 1024,
            },
            data: { dbPlaces, dbRoutes, dbCommutes },
          });
        }
      } catch (e) {
        console.error(e);
        alert('File upload error!');
      }
    };
    r.readAsArrayBuffer(file);
  }

  render() {
    return (
      <div>
        <div
          onDragOver={e => this.dragOver(e)}
          onDragLeave={e => this.dragLeave(e)}
          onDrop={e => this.drop(e)}
          className={`mg-file-loader${this.state.isDragOver ? ' dragover' : ''}`}
        >
          <h5>Drag & Drop</h5>
          <p>*.db3 file or</p>
          <p>
            <label>browse files <input type="file" onChange={e => this.inputChange(e)} /></label> to upload
          </p>
        </div>
        {this.state.noCommutes && <NoCommutesMessage {...this.state.noCommutes} />}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  uploadFile: bindActionCreators(uploadFile, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FileLoader);
