export class Drawer {
  prevState;
  map;
  group;

  constructor(map, group) {
    this.map = map;
    this.group = group;
  }

  draw(state) {
    throw new Error('Abstract method. Implement me.');
  };

  update(state) {
    this.prevState = state;
  }
}
