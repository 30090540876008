import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import './logout.css';

class Logout extends Component {
  render() {
    return (
      <button className="mg-log-out" onClick={this.logout.bind(this)}>Log Out</button>
    );
  }

  logout() {
    this.props.logout();
    this.props.history.push('/');
  }
}

Logout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

export default withRouter(Logout);
