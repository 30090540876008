import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logout from './logout/Logout';

import './styles/userMenuPopup.css';
import {dateFormat} from '../../utils/date';

export class UserMenuPopup extends Component {
  render () {
    return <div className="mg-user-menu-popup" ref={el => this.container = el}>
      <div>User ID: <i>{this.props.user.userId}</i></div>
      <div>Last Synced: <i>{this.getTimeString()} (UTC time)</i></div>
      {this.getLogoutEl()}
    </div>
  }

  getTimeString() {
    return this.props.lastSync ? dateFormat(this.props.lastSync) : '...';
  }

  getLogoutEl() {
    const { isLoggedIn, logout } = this.props;
    return isLoggedIn ? <Logout logout={logout} /> : null;
  }

  getDomNode() {
    return this.container;
  }
}

UserMenuPopup.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  lastSync: PropTypes.number,
};
