import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import './style.css';

export default class ScoreDistribution extends Component {
  constructor(props) {
    super(props);
    this.hAxisValues = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    this.vAxisValues = [
      '00:00 am', '02:00', '04:00', '06:00', '08:00', '10:00',
      '12:00 pm', '02:00', '04:00', '06:00', '08:00', '10:00',
    ];
  }

  range(start, end, step) {
    return _.range(start, end, step);
  }

  getScores(commuteData, weekday, hour) {
    return _.get(commuteData, `commute.scoreDistribution[${weekday}][${hour}]`, []);
  }

  getScore(commuteData, weekday, hour) {
    if (!commuteData) {
      return '';
    }
    const scores = this.getScores(commuteData, weekday, hour);
    if (!scores.length) {
      return '';
    }
    return `+${+scores.reduce((a, b) => a + b.score, 0).toFixed(2)}`;
  }

  getScoreClasses(commuteData, weekday, hour) {
    const score = +this.getScore(commuteData, weekday, hour);
    return `tooltip-on-hover
      ${score < 2 ? ' point-size-XS' : ''}
      ${score >= 2 && score < 3 < 2 ? ' point-size-S' : ''}
      ${score >= 3 && score < 4 ? ' point-size-M' : ''}
      ${score >= 4 ? ' point-size-L' : ''}
    `;
  }

  render() {
    const { selectedCommute } = this.props;

    return <table className="score-distribution">
      <tbody>
        {this.range(0, 24, 2).map((hour, hourIndex) =>
          <tr key={hourIndex}>
            <td className="v-axis">
              <span>{this.vAxisValues[hourIndex]}</span>
            </td>

            {this.range(7).map((weekday, weekdayIndex) =>
              <td key={weekdayIndex}>
                {this.getScore(selectedCommute, weekday, hour) &&
                  <i className={this.getScoreClasses(selectedCommute, weekday, hour)}>
                    {this.getScore(selectedCommute, weekday, hour)}
                    <span className="tooltip-text">
                      <p className="points-count">{this.getScore(selectedCommute, weekday, hour)} score</p>

                      {this.getScores(selectedCommute, weekday, hour).map((scoreObj, scoreIndex) =>
                        <p className="point" key={scoreIndex}>
                          {this.hAxisValues[weekday]}, {scoreObj.hour}:00 (UTC) <b>+{scoreObj.score.toFixed(2)}</b>
                        </p>
                      )}

                    </span>
                  </i>
                }
              </td>
            )}
          </tr>
        )}
        <tr className="h-axis">
          <td className="v-axis">
            <span>12:00 am</span>
          </td>
          {this.hAxisValues.map((val, hAxisIndex) =>
            <td key={hAxisIndex}>
              {val}
            </td>
          )}
        </tr>
      </tbody>
  </table>;
  }
}

ScoreDistribution.propTypes = {
  selectedCommute: PropTypes.object.isRequired,
};
