import {mgFetchPost, RequestError} from '../../utils/fetch';
import {PROXY_URL} from '../../config';

const NS = 'pushNotification';
const SET_PUSH_NOTIFICATION = `${NS}/SET_PUSH_NOTIFICATION`;

export default function (state = null, action) {
  switch (action.type) {
    case SET_PUSH_NOTIFICATION:
      return action.payload;
    default:
      return state;
  }
}

export function loadPushNotification(xmlStr) {
  return async (dispatch, getState) => {
    const pseudoUserId = JSON.parse(atob(getState().user.token.split('.')[1])).sub;
    let notification;
    try {
      notification = await mgFetchPost(`${PROXY_URL}/mg-push-notification`, {
        xml: xmlStr,
        pseudoUserId,
      });
    } catch (e) {
      console.log(e instanceof RequestError);
      notification = e.data || { error: e.message };
    }
    dispatch({ type: SET_PUSH_NOTIFICATION, payload: notification });
  };
}
