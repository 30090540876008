import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rx from 'rxjs/Rx';
import { numToChar } from '../../../../utils'
import { addressParser } from "../../../../state/cloudSyncData/table-parsers/common-parsers";
import './style.css';
import * as _ from 'lodash';

export default class PredictionsList extends Component {
  state = {
    locationsList: [],
  };

  componentDidMount() {
    this.loadAddresses(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locations, this.props.locations)) {
      this.loadAddresses(this.props);
    }
  }

  loadAddresses(props) {
    Rx.Observable.forkJoin(
      props.locations.map(location => addressParser({
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      }))
    ).subscribe(addresses =>
      this.setState({
        locationsList: addresses.reduce((result, address, index) => ({
          ...result,
          [`${props.locations[index].coords.latitude},${props.locations[index].coords.longitude}`]: address
        }), [])
      })
    );
  }

  render() {
    const {locations, routes, hoveredPrediction, hoveredPredictionRoute, selectedPrediction} = this.props;

    return <div>
      {locations.map((location, index) => <ul
        className={"mg-predictions-list" + (selectedPrediction === index ? ' selected' : '')}
        key={index}
        onMouseEnter={() => this.onHoverPrediction(index)}
        onMouseLeave={() => this.onHoverPrediction(null)}
        onClick={() => this.onSelectPrediction(index)}
      >
        <li className={hoveredPrediction === index ? 'hovered' : ''}>
          <span className="prediction-num">{index + 1}</span>
          <span className="prediction-location">
            {this.state.locationsList[`${location.coords.latitude},${location.coords.longitude}`] || '...'}
          </span>
          <span className="prediction-score">+{location.score.toPrecision(6)}</span>
        </li>

        {selectedPrediction === index && routes[index] && routes[index].map((route, routeIndex) => <li
          className={'route' + (hoveredPredictionRoute === routeIndex ? ' hovered' : '')}
          key={routeIndex}
          onMouseEnter={() => this.onHoverRoute(index, routeIndex)}
          onMouseLeave={() => this.onHoverRoute(index, null)}
        >
          <span className="prediction-num">{numToChar(routeIndex)}</span>
          <span className="prediction-location">Route {routeIndex + 1}</span>
          <span className="prediction-score">+{route.score.toPrecision(6)}</span>
        </li>)}

      </ul>)}
    </div>
  }

  onHoverPrediction(index) {
    this.props.setHoveredPrediction(index);
  }

  onHoverRoute(predictionIndex, routeIndex) {
    if (this.props.selectedPrediction === predictionIndex) {
      this.props.setHoveredPredictionRoute(routeIndex);
    }
  }

  onSelectPrediction(index) {
    if (this.props.selectedPrediction === index) {
      this.deselect();
      return;
    }
    this.props.setSelectedPrediction(index);
  }

  deselect() {
    this.onSelectPrediction(null);
  }
}

PredictionsList.propTypes = {
  locations: PropTypes.array.isRequired,
  routes: PropTypes.arrayOf(PropTypes.array).isRequired,
  hoveredPrediction: PropTypes.number,
  hoveredPredictionRoute: PropTypes.number,
  selectedPrediction: PropTypes.number,
  setHoveredPrediction: PropTypes.func.isRequired,
  setHoveredPredictionRoute: PropTypes.func.isRequired,
  setSelectedPrediction: PropTypes.func.isRequired,
};
