import * as _ from 'lodash';
import {commuteParser} from './commute';
import {placeParser} from './place';
import {routeParser} from './route';

export function parseOnlineData(cloudSyncData) {
  const data = _.get(cloudSyncData, 'data', []);
  const idPath = 'objectData.Model.Identifier';
  const dataById = data.reduce((red, obj) => {
    const id = _.get(obj, idPath);
    if (id) {
      red[id] = obj;
    }
    return red;
  }, {});
  const analysis = {
    rawData: cloudSyncData,
    commutes: {},
    places: {},
    routes: {},
    lastSync: parseLastSync(cloudSyncData),
    placesCommutesIndex: {},
  };

  return data.reduce((red, obj) => {
    const id = _.get(obj, idPath);
    const type = _.get(obj, 'objectData.ModelType');
    switch (type) {
      case 'Place':
        red.places[id] = placeParser(obj, dataById);
        break;
      case 'Transition':
        const commute = commuteParser(obj, dataById);
        red.commutes[id] = commute;
        if (!red.placesCommutesIndex[commute.startPlaceId]) {
          red.placesCommutesIndex[commute.startPlaceId] = {
            start: [],
            end: [],
          };
        }

        red.placesCommutesIndex[commute.startPlaceId].start.push(commute.id);
        if (!red.placesCommutesIndex[commute.endPlaceId]) {
          red.placesCommutesIndex[commute.endPlaceId] = {
            start: [],
            end: [],
          };
        }
        red.placesCommutesIndex[commute.endPlaceId].end.push(commute.id);
        break;
      case 'Route':
        red.routes[id] = routeParser(obj);
        break;
      default:
        return red;
    }
    return red;
  }, analysis);
}

function parseLastSync(rawData) {
  const data = _.get(rawData, 'data', []);
  const editTimestamps = data
    .map(value => _.get(value, 'objectData.Model.LastEditTimeMs'))
    .filter(Boolean);

  return editTimestamps.sort().reverse()[0];
}
