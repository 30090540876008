import React from 'react';
import PropTypes from 'prop-types';
import './removeSubscription.scss';

class RemoveSubscription extends React.Component {
  static propTypes = {
    pos: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  onRemove = () => {
    const { onRemove, id } = this.props;
    onRemove(id);
  };

  render() {
    const { pos } = this.props;

    return (
      <div className="mg-remove-subscription" style={{ top: pos.y, left: pos.x }}>
        <button onClick={this.onRemove}>Remove subscription</button>
      </div>
    );
  }
}

export default RemoveSubscription;
