import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './fileHeader.css';

export default class FileHeader extends Component {
  render() {
    const { file, close } = this.props;
    return (
      <section className="mg-file-header">
        <span className="mg-file-header-info">{file.name}</span>
        <span className="mg-file-header-size">({file.size} kb)</span>
        <button className="mg-file-header-close" onClick={close}>Close</button>
      </section>
    );
  }
}

FileHeader.propTypes = {
  close: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
};
