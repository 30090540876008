import React, { Component } from 'react';
import Left from '../shared/left/Left';
import Map from '../shared/map/Map';
import Login from '../login/Login';
import {getIsLoggedIn, getUser} from '../../state/user/getters';
import {login} from '../../state/user/reducer';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getLoginUi} from '../../state/ui/login/getters';
import {withRouter} from 'react-router-dom';
import AnalysisLeft from './analysisLeft/AnalysisLeft';
import {getFilters} from '../../state/filters/getters';
import {resetFilters, updateFilters} from '../../state/filters/reducer';
import {getSelectedMGObject} from '../../state/selectedMGObject/getters';
import {selectCommute, selectPlace, selectRoute, setMGObject} from '../../state/selectedMGObject/reducer';
import {getTableData} from '../../state/cloudSyncData/getters';
import {setHoveredCommute} from '../../state/hoveredCommute/reducer';
import {setHoveredPlace} from '../../state/hoveredPlace/reducer';
import {setHoveredRoute} from '../../state/hoveredRoute/reducer';
import AnalysisMap from './map/AnalysisMap';
import {getHoveredId} from '../../state/getters';
import FloatingPanels from '../floating-panels';

class Analysis extends Component {
  render() {
    return (
      <div>
        <Left>
          {this.getLeftElement()}
        </Left>
        <FloatingPanels />
        <Map
            selectedRouteId={this.props.selectedRouteId}
            selectedPlaceId={this.props.selectedPlaceId}
            selectedCommuteId={this.props.selectedCommuteId}
        >
          <AnalysisMap
            state={this.props.state}
            selectPlace={this.props.selectPlace}
            selectCommute={this.props.selectCommute}
            selectRoute={this.props.selectRoute}
            setHoveredCommute={this.props.setHoveredCommute}
            setHoveredPlace={this.props.setHoveredPlace}
            setHoveredRoute={this.props.setHoveredRoute}
          />
        </Map>
      </div>
    );
  }

  getLeftElement() {
    if (this.props.isLoggedIn) {
      return <AnalysisLeft
        filters={this.props.filters}
        updateFilters={this.props.updateFilters}
        resetFilters={this.props.resetFilters}
        setMGObject={this.props.setMGObject}
        selectedMGObject={this.props.selectedMGObject.type}
        tableData={this.props.tableData}
        selectPlace={this.props.selectPlace}
        selectCommute={this.props.selectCommute}
        selectRoute={this.props.selectRoute}
        setHoveredCommute={this.props.setHoveredCommute}
        setHoveredPlace={this.props.setHoveredPlace}
        setHoveredRoute={this.props.setHoveredRoute}
        hoveredId={this.props.hoveredId}
      />;
    }
    return (
      <Login
        login={this.props.login}
        ui={this.props.loginUi}
      />
    )
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
  isLoggedIn: getIsLoggedIn(state) || !!state.file,
  loginUi: getLoginUi(state),
  filters: getFilters(state),
  selectedMGObject: getSelectedMGObject(state),
  tableData: getTableData(state),
  state: state,
  hoveredId: getHoveredId(state),
  selectedCommuteId: state.selectedMGObject.selectedCommuteId,
  selectedPlaceId: state.selectedMGObject.selectedPlaceId,
  selectedRouteId: state.selectedMGObject.selectedRouteId,
});

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch),
  updateFilters: bindActionCreators(updateFilters, dispatch),
  resetFilters: bindActionCreators(resetFilters, dispatch),
  setMGObject: bindActionCreators(setMGObject, dispatch),
  selectCommute: bindActionCreators(selectCommute, dispatch),
  selectRoute: bindActionCreators(selectRoute, dispatch),
  selectPlace: bindActionCreators(selectPlace, dispatch),
  setHoveredCommute: bindActionCreators(setHoveredCommute, dispatch),
  setHoveredPlace: bindActionCreators(setHoveredPlace, dispatch),
  setHoveredRoute: bindActionCreators(setHoveredRoute, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Analysis));
