import {LOGOUT} from '../../user/reducer';

const NS = 'predictions/destinations';
export const SET_DESTINATIONS_DATA = `${NS}/SET_DESTINATIONS_DATA`;
const SET_ROUTES_DATA = `${NS}/SET_ROUTES_DATA`;
const SET_DEPARTURE_WINDOW_DATA = `${NS}/SET_DEPARTURE_WINDOW_DATA`;

function getInitialState() {
  return {
    destinations: null,
    routes: null,
  };
}

export default function response(state = getInitialState(), action) {
  switch (action.type) {
    case SET_DESTINATIONS_DATA:
      return {
        ...state,
        destinations: action.payload,
      };
    case SET_ROUTES_DATA:
      return {
        ...state,
        routes: action.payload,
      };
    case SET_DEPARTURE_WINDOW_DATA:
      return {
        ...state,
        departureWindow: action.payload,
      };
    case LOGOUT:
      return getInitialState();
    default:
      return state;
  }
}

export function setPredictionDestinationsData(data) {
  return {
    type: SET_DESTINATIONS_DATA,
    payload: data,
  };
}

export function setPredictionRoutesData(data) {
  return {
    type: SET_ROUTES_DATA,
    payload: data,
  };
}

export function setPredictionDepartureWindowData(data) {
  return {
    type: SET_DEPARTURE_WINDOW_DATA,
    payload: data,
  };
}
