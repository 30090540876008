import React from "react";
import ReactDOM from "react-dom";
import "@lui/core/dist/lui-core.min";
import "./index.css";
import App from "./views/App";
import { unregister } from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";
import { reducers } from "./state";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import "leaflet/dist/leaflet.css";

const logger = createLogger();

const store = createStore(
  combineReducers(reducers),
  applyMiddleware(thunk, logger)
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

unregister();
