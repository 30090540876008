import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UserMenuPopup } from "./UserMenuPopup";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/fontawesome-free-solid';

import './styles/userMenuToggler.css';

export class UserMenuToggler extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onHidePopup = this.onHidePopup.bind(this);
    this.state = {
      isSettingsShown: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.onHidePopup, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onHidePopup, false);
  }

  onHidePopup(e) {
    if (!this.popup) return;
    let popup = this.popup.getDomNode();
    if (!popup.contains(e.target) && this.state.isSettingsShown) {
      this.close();
    }
  }

  onClick(e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    this.setState({
      isSettingsShown: !this.state.isSettingsShown,
    });
  }

  close() {
    this.setState({
      isSettingsShown: false,
    });
  }

  render() {
    const { isLoggedIn, logout, user, lastSync } = this.props;

    return (
      <div className="mg-user-menu-toggler">
        <div className="wrapper" onClick={this.onClick}>
          <span className="user-name">Signed in as <i>{this.props.user.username}</i></span>
          <span className="toggle-icon">
            <FontAwesomeIcon icon={faCog} style={{ color: this.state.isSettingsShown ? '#48dad0' : '#bbb' }} />
          </span>
        </div>
        {this.state.isSettingsShown && <UserMenuPopup
          ref={el => this.popup = el}
          isLoggedIn={isLoggedIn}
          logout={logout}
          user={user}
          lastSync={lastSync}
        />}
      </div>
    );
  }
}

UserMenuToggler.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  lastSync: PropTypes.number,
};

export default UserMenuToggler;
