import * as _ from 'lodash';
import { distanceBetweenPoints, distTransform, durationTransform } from "../../../views/shared/map/utils/index";
import { totalScoreParser } from './common-parsers';

export function routeParser(rawRoute) {
  let totalDistance = 0;
  let lastWaypoint = null;
  let trafficDelay = 0;
  const waypoints = _.get(rawRoute, 'objectData.Model.Waypoints[0].Waypoints', []).map(waypoint => {
    if (!lastWaypoint) {
      lastWaypoint = waypoint.Position;
    } else {
      totalDistance += distanceBetweenPoints(
        { lat: lastWaypoint.latitude, lng: lastWaypoint.longitude},
        { lat: waypoint.Position.latitude, lng: waypoint.Position.longitude},
      );
      lastWaypoint = waypoint.Position;
    }
    trafficDelay += _.get(waypoint, 'TrafficDelaySeconds', 0);
    return {
      lat: waypoint.Position.latitude,
      lng: waypoint.Position.longitude,
    };
  });
  const duration = _.get(rawRoute, 'objectData.Model.TravelTimeSeconds');

  return {
    id: _.get(rawRoute, 'objectData.Model.Identifier'),
    commuteId: _.get(rawRoute, 'objectData.Model.Transition.ObjectIdentifier'),
    waypoints: waypoints,
    dist: distTransform(totalDistance),
    creationTime: _.get(rawRoute, 'objectData.Model.OnDeviceCreationTimeMs'),
    recordedTime: _.get(rawRoute, 'objectData.Model.CreationTimeMs'),
    lastEdited: _.get(rawRoute, 'objectData.Model.LastEditTimeMs'),
    duration: durationTransform(duration),
    raw: rawRoute,
    start: parseStart(rawRoute),
    end: parseEnd(rawRoute),
    trafficDelay: durationTransform(trafficDelay),
    score: totalScoreParser(_.get(rawRoute, 'objectData.LearnedDatas')),
    isDeleted: _.get(rawRoute, 'objectData.IsDeleted'),
  };
}

function parseStart(rawRoute) {
  return {
    lat: _.get(rawRoute, 'objectData.Model.Start.latitude'),
    lng: _.get(rawRoute, 'objectData.Model.Start.longitude'),
  };
}

function parseEnd(rawRoute) {
  return {
    lat: _.get(rawRoute, 'objectData.Model.End.latitude'),
    lng: _.get(rawRoute, 'objectData.Model.End.longitude'),
  };
}
