import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './button.css';

export default class Button extends Component {
  render() {
    return (
      <button
        className="mg-button"
        {...this.props}
      />
    );
  }
}

Button.propTypes = {
  disabled: PropTypes.bool,
};
