import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class CloseFloatingPanel extends Component {
  render() {
    return <div className="mg-close-floating-panel" onClick={this.props.onClose}>&times;</div>;
  }
}

CloseFloatingPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
};
