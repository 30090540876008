import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import * as moment from 'moment';
import './predictionsLeft.scss';
import 'react-datetime/css/react-datetime.css';
import { generateTimezoneList, getWeekDay } from '../../../utils/date';
import Weekdays from "../../analysis/filters/weekdays/Weekdays";
import Timetable from "../../timetable/Timetable";
import Button from "../../shared/button/Button";
import PredictionsList from "./PredictionsList/index";
// import DepartureWindow from '../departureWindow/DepartureWindow';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faClock, faCalendarAlt } from '@fortawesome/fontawesome-free-solid';

export default class PredictionsLeft extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      syncing: false,
    };

    this.onWeekdayChange = this.onWeekdayChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onZoneChange = this.onZoneChange.bind(this);
    this.onSyncClick = this.onSyncClick.bind(this);
    this.onMaxDestsPredictedChange = this.onMaxDestsPredictedChange.bind(this);
    this.onMaxRoutesPredictedChange = this.onMaxRoutesPredictedChange.bind(this);
  }

  render() {
    let { formData } = this.props;
    const zonesList = generateTimezoneList();
    const weekDay = getWeekDay(this.props.formData.date);

    return (
      <div className="mg-predictions-left">
        <lui-desktoplayer-theme>
          <lui-contentchromelayer-theme>
            {formData.coords === null && <div className="select-location-message form-row">
              Please select a location in the map (by right-click)
            </div>}

            <div className="coordinates form-row flex">
              <span>
                Selected coordinates:
              </span>
              {formData.coords === null ? <div>Not Selected</div> : <div className="coords-input">
                <lui-textfield>
                  <input type="text" value={formData.coords.lat + ',' + formData.coords.lng} readOnly/>
                </lui-textfield>
              </div>}
            </div>

            <div className="datetime form-row">
                <Weekdays weekdays={[weekDay === 0, weekDay === 1, weekDay === 2, weekDay === 3, weekDay === 4, weekDay === 5, weekDay === 6]} onClick={this.onWeekdayChange}/>
              <div className="flex">
                <div className="date">
                  Date:
                  <div className="input">
                    <Datetime dateFormat="YYYY-MM-DD" timeFormat={false} value={formData.date} onChange={this.onDateChange} closeOnSelect />
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </div>
                </div>
                <div className="time">
                  Time:
                  <div className="input">
                    <Datetime dateFormat={false} timeFormat="HH:mm" value={formData.time} onChange={this.onTimeChange} closeOnSelect />
                    <FontAwesomeIcon icon={faClock} />
                  </div>
                </div>
                <div className="zone">
                  Zone:<br/>
                  <select name="zones" value={formData.zone} onChange={this.onZoneChange}>
                    {zonesList.map(zone => <option value={zone} key={zone}>{zone}</option>)}
                  </select>
                </div>
              </div>
            </div>

            <div className="flex max-inputs">
              <div className="form-row">
                <span className="label">Max Dests predicted:</span>
                <lui-textfield type="number">
                  <input value={formData.maxDestsPredicted} onChange={this.onMaxDestsPredictedChange} />
                </lui-textfield>
              </div>
              <div className="form-row">
                <span className="label">Max Routes predicted:</span>
                <lui-textfield type="number">
                  <input value={formData.maxRoutesPredicted} onChange={this.onMaxRoutesPredictedChange} />
                </lui-textfield>
              </div>
            </div>

            {this.props.response.destinations && <Timetable
              data={this.responseToTableData(this.props.response.destinations)}
              hoveredItem={this.props.hoveredPrediction}
              uiZoom={this.props.uiZoom}
            />}

            {/*<DepartureWindow data={this.props.response.departureWindow}/>*/}

            {(this.props.response.destinations && this.props.response.routes) && <PredictionsList
              locations={this.getPredictedDestinations(this.props.response.destinations)}
              routes={this.getPredictedRoutes(this.props.response.routes)}
              hoveredPrediction={this.props.hoveredPrediction}
              setHoveredPrediction={this.props.setHoveredPrediction}
              hoveredPredictionRoute={this.props.hoveredPredictionRoute}
              selectedPrediction={this.props.selectedPrediction}
              setHoveredPredictionRoute={this.props.setHoveredPredictionRoute}
              setSelectedPrediction={this.props.setSelectedPrediction}
            />}

            {this.props.formData.coords && <Button onClick={this.onSyncClick} disabled={this.state.syncing}>PNAV Prediction</Button>}
          </lui-contentchromelayer-theme>
        </lui-desktoplayer-theme>
      </div>
    );
  }

  responseToTableData(response) {
    const data = [[], [], [], [], [], [], []];

    if (response) {
      response._embedded.prediction.forEach((prediction, key) => {
        const date = new Date(prediction.destination.Position.EditTimestampMs);
        let weekDay = date.getDay();
        const time = date.getHours();
        if (!data[weekDay][time]) {
          data[weekDay][time] = { keys: [key] };
        } else {
          data[weekDay][time].keys.push(key);
        }
      });
    }

    return data;
  }

  getPredictedDestinations(response) {
    return response._embedded.prediction.map(prediction => ({
      coords: prediction.destination.Position.Coordinates,
      score: prediction.score,
    }));
  }

  getPredictedRoutes(response) {
    return response._embedded.prediction.map(prediction => prediction.routes.map(routeObj => ({
      score: routeObj.route.Score,
    })));
  }

  onWeekdayChange(value) {
    this.props.setFormData({
      date: moment(this.props.dateTime).weekday(value).format('YYYY-MM-DD')
    });
  }

  onDateChange(momentObj) {
    const date = typeof momentObj === 'string' ? momentObj : momentObj.format('YYYY-MM-DD');
    this.props.setFormData({ date });
  }

  onTimeChange(momentObj) {
    const time = typeof momentObj === 'string' ? momentObj : momentObj.format('HH:mm');
    if (typeof momentObj === 'string') return;
    this.props.setFormData({ time });
  }

  onZoneChange(evt) {
    this.props.setFormData({
      zone: evt.target.value,
    });
  }

  onMaxDestsPredictedChange(evt) {
    this.props.setFormData({
      maxDestsPredicted: +evt.target.value,
    });
  }

  onMaxRoutesPredictedChange(evt) {
    this.props.setFormData({
      maxRoutesPredicted: +evt.target.value,
    });
  }

  async onSyncClick() {
    this.setState({ syncing: true });
    await this.props.setPredictionDestinationsData();
    this.setState({ syncing: false });
  }
}

PredictionsLeft.propTypes = {
  response: PropTypes.object,
  formData: PropTypes.object.isRequired,
  hoveredPrediction: PropTypes.number,
  hoveredPredictionRoute: PropTypes.number,
  selectedPrediction: PropTypes.number,
  setFormData: PropTypes.func.isRequired,
  setHoveredPrediction: PropTypes.func.isRequired,
  setPredictionDestinationsData: PropTypes.func.isRequired,
  setHoveredPredictionRoute: PropTypes.func.isRequired,
  setSelectedPrediction: PropTypes.func.isRequired,
  uiZoom: PropTypes.number.isRequired,
};
