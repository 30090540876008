import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as _ from 'lodash';

import Json from '../shared/json/Json';
import {loadPushNotification} from '../../state/pushNotification/reducer';
import defaultXml from './defaultXml';
import './push-notifications.css'
import DebugNotifications from './DebugNotifications';
import { getPseudoUserId } from '../../state/user/getters';
import { addNotification } from '../../state/ui/notification/reducer';

class PushNotification extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isXMLValid: true,
    };
    this.bodyChanged = _.debounce(this.bodyChanged.bind(this), 100)
  }

  bodyChanged() {
    const xmlParser = new DOMParser();
    const xml = xmlParser.parseFromString(this.xmlEl.value, "application/xml");

    if (xml.getElementsByTagName('parsererror').length > 0) {
      this.setState({ isXMLValid: false });
    } else {
      this.setState({ isXMLValid: true })
    }
  }

  request(e) {
    e.preventDefault();
    this.props.loadPushNotification(this.xmlEl.value);
  }

  render() {
    const { pushNotification, addNotification, pseudoUserId } = this.props;
    return <div className="mg-push-notification">
      <div className="left">
        <form onSubmit={e => this.request(e)}>
          <div className="form-group">
            <label>XML:</label>
            <textarea
              className={this.state.isXMLValid ? '' : 'invalid'}
              name="body"
              defaultValue={defaultXml}
              ref={el => { this.xmlEl = el; }}
              onChange={this.bodyChanged}
            />
          </div>
          <button className="mg-button" disabled={!this.state.isXMLValid}>Request</button>
        </form>
        <div className="mg-divider" />
        <DebugNotifications addNotification={addNotification} pseudoUserId={pseudoUserId} />
      </div>
      <div className="right">
        <Json data={pushNotification} />
      </div>
    </div>;
  }
}

const mapStateToProps = state => ({
  pushNotification: state.pushNotification,
  pseudoUserId: getPseudoUserId(state),
});

const mapDispatchToProps = {
  loadPushNotification,
  addNotification,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushNotification));
