const NS = 'filters';

const UPDATE = `${NS}/UPDATE`;
const RESET = `${NS}/RESET`;

function getDefaultState() {
  return {
    weekdays: [true, true, true, true, true, true, true],
    hours: {
      from: 0,
      to: 24,
    }
  };
}


export default function (state = getDefaultState(), action) {
  switch (action.type) {
    case UPDATE:
      return action.payload;
    case RESET:
      return getDefaultState();
    default:
      return state;
  }
}

export function updateFilters(newFiltersData) {
  return { type: UPDATE, payload: newFiltersData };
}

export function resetFilters() {
  return { type: RESET };
}
