export function parsePlaces(dbPlaces) {
  return dbPlaces.reduce((red, dbPlace) => {
    red[dbPlace[0]] = {
      id: dbPlace[0],
      point: {
        lat: dbPlace[2],
        lng: dbPlace[3],
        address: dbPlace[4],
      },
      commutesCount: 0,
      score: dbPlace[5],
      creationTime: dbPlace[1],
      raw: null,
    };
    return red;
  }, {});
}
