import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './inputGroup.css';

export default class InputGroup extends Component {
  render() {
    return (
      <div className="mg-input-group">
        <span>
          <FontAwesomeIcon icon={this.props.icon} />
        </span>
        <input {...this.props} />
      </div>
    );
  }
}

InputGroup.propTypes = {
  icon: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
