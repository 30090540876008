import { SET as SET_HOST } from '../host/constants';
import { proxyFetch } from '../../../utils/fetch';
import { getUserToken } from '../../user/getters';
import { getSubscriptionsHost } from '../host/reducer';
import { setSubscrIsLoading } from '../ui/reducer';
import { errorNotification } from '../helpers';

const SET = 'subscriptions/subscriptions/SET';
const ADD = 'subscriptions/subscriptions/ADD';
const DELETE = 'subscriptions/subscriptions/DELETE';

export default (state = null, action) => {
  switch (action.type) {
    case SET_HOST:
      return null;
    case SET:
      return action.payload;
    case ADD:
      return [...state, action.payload];
    case DELETE: {
      const index = state.findIndex((subscription) => subscription.id === action.payload);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    default:
      return state;
  }
}

export const loadSubscriptions = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(setSubscrIsLoading('subscriptions', true));

      const token = getUserToken(state);
      const url = `${getSubscriptionsHost(state)}/subscriptions/traffic_notifications`;
      const res = await proxyFetch(token, url, null, 'GET');
      if (res.status >= 400) {
        await errorNotification(dispatch, res);
      } else {
        const json = await res.json();
        dispatch({ type: SET, payload: json._embedded.subscriptions });
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setSubscrIsLoading('subscriptions', false));
    }
  };
};

export const addSubscription = (latLng) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(setSubscrIsLoading('addSubscription', true));

      const token = getUserToken(state);
      const url = `${getSubscriptionsHost(state)}/subscriptions/traffic_notifications`;
      const body = {
        location: {
          lat: latLng.lat,
          lon: latLng.lng,
        },
      };
      const res = await proxyFetch(token, url, body);
      if (res.status >= 400) {
        await errorNotification(dispatch, res);
      } else {
        dispatch({ type: ADD, payload: body });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setSubscrIsLoading('addSubscription', false));
    }
  };
};

export const deleteSubscription = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(setSubscrIsLoading('deleteSubscription', true));

      const token = getUserToken(state);
      const url = `${getSubscriptionsHost(state)}/subscriptions/traffic_notifications/${id}`;
      const res = await proxyFetch(token, url, null, 'DELETE');
      if (res.status >= 400) {
        await errorNotification(dispatch, res);
      } else {
        dispatch({ type: DELETE, payload: id });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setSubscrIsLoading('deleteSubscription', false));
    }
  };
};

export const getSubscriptionsList = (state) => state.subscriptions.subscriptions;
