import {createFinishMarker, createRoute, createStartMarker} from '../../shared/map/utils';
import {
  COMMUTE_LINE_OPTIONS, createCommuteRoute, createMarker, DEFAULT_ICON, FINISH_ICON, START_ICON
} from '../../shared/map/utils';
import {Drawer} from '../../shared/map/utils/drawer';
import {applyGeneralFilter} from '../../../state/cloudSyncData/table-parsers';

const hoveredRouteOptions = {
  style: {
    strokeColor: 'orange',
    fillColor: 'orange',
    lineWidth: 4,
  },
};

export class CommutesDrawer extends Drawer {
  mapObjectGroups;
  hoveredGroup;
  onHoverCb;
  onClickCb;

  constructor(map, group, onHoverCb, onClickCb) {
    super(map, group);
    this.onHoverCb = onHoverCb;
    this.onClickCb = onClickCb;
  }

  draw(state) {
    if (state.selectedMGObject.selectedCommuteId) {
      this.drawSelectedCommute(state);
    } else if (state.selectedMGObject.selectedPlaceId) {
      this.drawPlaceCommutes(state);
    } else {
      this.drawCommutes(state.cloudSyncData.commutes, state.filters);
    }
  }

  update(state) {
    if (this.prevState && this.prevState.hoveredCommute !== state.hoveredCommute) {
      this.hoverCommute(state.hoveredCommute);
    }

    super.update(state);
  }

  drawPlaceCommutes(state) {
    const { selectedPlaceId } = state.selectedMGObject;
    const isRelatedCommute = (commute) => {
      return commute.startPlaceId === state.selectedMGObject.selectedPlaceId || commute.endPlaceId === selectedPlaceId;
    };
    const commutes = Object.keys(state.cloudSyncData.commutes).reduce((red, key) => {
      if (isRelatedCommute(state.cloudSyncData.commutes[key])) {
        red[key] = state.cloudSyncData.commutes[key];
      }
      return red;
    }, {});
    this.drawCommutes(commutes, state.filters);
  }

  drawCommutes(commutes, filters) {
    this.mapObjectGroups = {};

    Object.keys(commutes).forEach(key => {
      const commute = commutes[key];
      if (!applyGeneralFilter(commute, filters)) {
        return;
      }

      const commuteGroup = {
        startMarker: createMarker(commute.start),
        endMarker: createMarker(commute.end),
        route: this.addListenersAndData(createCommuteRoute(commute), commute.id),
      };
      this.mapObjectGroups[commute.id] = commuteGroup;

      this.group.addObjects([commuteGroup.startMarker, commuteGroup.endMarker, commuteGroup.route]);
    });
  }

  drawSelectedCommute(state) {
    const { commutes, routes } = state.cloudSyncData;
    const commute = commutes[state.selectedMGObject.selectedCommuteId];
    Object.keys(routes).forEach((key) => {
      const route = routes[key];
      if (route.commuteId === commute.id) {
        this.group.addObject(createRoute(route.waypoints));
      }
    });
    this.group.addObjects([
      createStartMarker(commute.start),
      createFinishMarker(commute.end),
      createCommuteRoute(commute),
    ]);
  }

  hoverCommute(hoveredCommute) {
    const removeHover = () => {
      this.hoveredGroup.startMarker.setIcon(DEFAULT_ICON);
      this.hoveredGroup.startMarker.setZIndex(0);
      this.hoveredGroup.endMarker.setIcon(DEFAULT_ICON);
      this.hoveredGroup.endMarker.setZIndex(0);
      this.hoveredGroup.route.setStyle(COMMUTE_LINE_OPTIONS.style);
      this.hoveredGroup.route.setZIndex(0);
    };

    if (hoveredCommute === null) {
      if (!this.hoveredGroup) {
        return;
      }
      removeHover();
    } else {
      if (this.hoveredGroup) {
        removeHover();
      }
      this.hoveredGroup = this.mapObjectGroups[hoveredCommute];
      this.hoveredGroup.startMarker.setIcon(START_ICON);
      this.hoveredGroup.startMarker.setZIndex(1);
      this.hoveredGroup.endMarker.setIcon(FINISH_ICON);
      this.hoveredGroup.endMarker.setZIndex(2);
      this.hoveredGroup.route.setStyle(hoveredRouteOptions.style);
      this.hoveredGroup.route.setZIndex(1);
    }
  }

  addListenersAndData(mapObject, id) {
    mapObject.addEventListener('pointerenter', this.onPointerEnter.bind(this));
    mapObject.addEventListener('pointerleave', this.onPointerLeave.bind(this));
    mapObject.addEventListener('tap', this.onClick.bind(this));
    mapObject.setData({ id });

    return mapObject;
  }

  onPointerEnter({ target }) {
    this.onHoverCb(target.getData().id);
    this.map.getElement().style.cursor = 'pointer';
  }

  onPointerLeave() {
    this.onHoverCb(null);
    this.map.getElement().style.cursor = 'auto';
  }

  onClick({ target }) {
    this.onClickCb(target.getData().id);
  }
}
