import { NS as UI_NS } from '../constant';
import localStorageReducerDecorator from "../../../utils/localStorageReducerDecorator";

const NS = `uiZoom`;
const SET_UI_ZOOM = `${UI_NS}/${NS}/SET_UI_ZOOM`;

const initState = 10;

function uiZoomReducer(state, action) {
  switch (action.type) {
    case SET_UI_ZOOM:
      return action.payload;
    default:
      return state;
  }
}

function uiZoomParser(item, defaultState) {
  return item === null ? defaultState : +item;
}

export default localStorageReducerDecorator(uiZoomReducer, 'uiZoom', initState, uiZoomParser);

export function setUiZoom(zoomLevel) {
  return { type: SET_UI_ZOOM, payload: zoomLevel };
}
