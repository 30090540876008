import * as moment from 'moment';
import { parseTimeZone } from '../../../utils/date';
import {LOGOUT} from '../../user/reducer';

const NS = 'predictions/formData';
const SET_DATA = `${NS}/SET_DATA`;

const getInitState = () => {
  const currentDate = new Date();
  let zone = parseTimeZone(-currentDate.getTimezoneOffset() / 60);
  return {
    coords: null,
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH:mm'),
    zone,
    maxDestsPredicted: 3,
    maxRoutesPredicted: 3,
  };
};

export default function formData(state = getInitState(), action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return getInitState();
    default:
      return state;
  }
}

export function setFormData(data) {
  return {
    type: SET_DATA,
    payload: data,
  };
}
