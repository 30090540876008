import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './weekdays.css';

export default class Weekdays extends Component {
  weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  onWeekdayClick(index) {
    if (this.props.onClick) {
      this.props.onClick(index);
    }
    if (!this.props.onChange) return;
    const weekdays = [...this.props.weekdays];
    weekdays[index] = !weekdays[index];
    this.props.onChange(weekdays);
  }

  render() {
    const weekdays = this.weekdays.map((weekday, index) => {
      const isSelected = this.props.weekdays[index];
      return (
        <li
          key={index}
          className={isSelected ? 'selected' : ''}
          onClick={this.onWeekdayClick.bind(this, index)}
        >{weekday}</li>
      );
    });
    return (
      <ul className="mg-weekdays">{weekdays}</ul>
    );
  }
}

Weekdays.propTypes = {
  weekdays: PropTypes.arrayOf(PropTypes.bool).isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};
