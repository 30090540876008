import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import './nav.css';

export default class Nav extends Component {
  render() {
    return (
      <nav className="mg-nav">
        <NavLink exact to="/">Analysis</NavLink>
        <NavLink exact to="/predictions">Predictions</NavLink>
        <NavLink exact to="/debug">Debug</NavLink>
        {
//////////////////////////
        }
        <NavLink exact to="/push-notifications">Push Notifications</NavLink>
        <NavLink exact to="/subscriptions">Subscriptions</NavLink>
        {
////////////////////
        }
      </nav>
    );
  }
}
