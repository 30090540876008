import {Component} from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {createGroup} from '../../shared/map/utils';
import { PredictionsDrawer } from "./predictions-drawer";

export default class PredictionsMap extends Component {
  drawer;
  group = createGroup();

  constructor(props) {
    super(props);

    this.onRightClick = this.onRightClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { map } = this.props;
    if (!map) {
      return;
    }

    let isInit = false;
    if (!this.drawer) {
      this.props.map.addEventListener('contextmenu', this.onRightClick, false);
      this.initDrawers(map);
      isInit = true;
    }

    if (isInit
      || !_.isEqual(this.props.state.predictions.formData.coords, prevProps.state.predictions.formData.coords)
      || !_.isEqual(this.props.state.predictions.response, prevProps.state.predictions.response)
      || !_.isEqual(this.props.state.predictions.selectedPrediction, prevProps.state.predictions.selectedPrediction)
      || !_.isEqual(this.props.state.cloudSyncData, prevProps.state.cloudSyncData)
    ) {
      this.drawer.draw(this.props.state);
    }

    if (isInit || !_.isEqual(this.props.state.cloudSyncData, prevProps.state.cloudSyncData)) {
      const bbox = this.group.getBoundingBox();
      if (bbox) {
        map.getViewModel().setLookAtData({bounds: bbox}, true);
      }
    }

    if (this.props.state.predictions.hoveredPrediction !== prevProps.state.predictions.hoveredPrediction) {
      if (prevProps.state.predictions.hoveredPrediction !== null) {
        this.drawer.setNotHoveredLine(prevProps.state.predictions.hoveredPrediction);
      }
      if (this.props.state.predictions.hoveredPrediction !== null) {
        this.drawer.setHoveredLine(this.props.state.predictions.hoveredPrediction);
      }
    }

    if (this.props.state.predictions.hoveredPredictionRoute !== prevProps.state.predictions.hoveredPredictionRoute) {
      if (prevProps.state.predictions.hoveredPredictionRoute !== null) {
        this.drawer.setNotHoveredRoute(prevProps.state.predictions.hoveredPredictionRoute);
      }
      if (this.props.state.predictions.hoveredPredictionRoute !== null) {
        this.drawer.setHoveredRoute(this.props.state.predictions.hoveredPredictionRoute);
      }
    }
  }

  componentWillUnmount() {
    if (!this.props.map) {
      return;
    }
    this.props.map.removeObject(this.group);
    this.props.map.removeEventListener('contextmenu', this.onRightClick);
  }

  render() {
    return null;
  }

  onRightClick(e) {
    this.props.setFormData({
      coords: this.props.map.screenToGeo(e.viewportX, e.viewportY),
    });
  }

  initDrawers(map) {
    map.addObject(this.group);
    this.drawer = new PredictionsDrawer({
      map,
      group: this.group,
      onHoverLineCb: this.props.setHoveredPrediction,
      onHoverRouteCb: this.props.setHoveredPredictionRoute,
      onClickLineCb: this.props.setSelectedPrediction,
    });
  }
}

PredictionsMap.propTypes = {
  state: PropTypes.object.isRequired,
  setHoveredPrediction: PropTypes.func.isRequired,
  setHoveredPredictionRoute: PropTypes.func.isRequired,
  setSelectedPrediction: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
};
