import {distanceBetweenPoints, distTransform, durationTransform} from '../../../views/shared/map/utils';

function cutOffExtraPoints(points, destinationPoint) {
  let lastDistance = null;
  const newPoints = [];

  for (let i = 0, l = points.length; i < l; i++) {
    const point = {
      lat: points[i][4],
      lng: points[i][5],
    };
    const distance = distanceBetweenPoints(point, destinationPoint);
    if (lastDistance === null || distance < lastDistance) {
      lastDistance = distance;
    } else {
      break;
    }
    newPoints.push(points[i]);
  }
  return newPoints;
}

export function parseRoutes(dbRoutes, commutes) {
  const res = {};

  let prevRouteId = null;
  let prevRouteEl = null;
  let prevReverseGeometry = null;
  let linkGeometry = [];
  let totalTrafficDelay = 0;

  let groupedDbRoutes = {};
  dbRoutes.forEach(dbRoute => {
    const routeId = dbRoute[0];
    if (!groupedDbRoutes[routeId]) {
      groupedDbRoutes[routeId] = [];
    }
    groupedDbRoutes[routeId].push(dbRoute);
  });

  dbRoutes.forEach((dbRoute, index) => {
    const routeId = dbRoute[0];
    const timestamp = dbRoute[1];
    const commuteId = dbRoute[2];
    const roadEl = dbRoute[3];
    const lat = dbRoute[4];
    const lng = dbRoute[5];
    const reverseGeometry = dbRoute[6];
    const routeDuration = dbRoute[7];
    const startLat = dbRoute[9];
    const startLng = dbRoute[10];
    const endLat = dbRoute[11];
    const endLng = dbRoute[12];
    const trafficDelay = dbRoute[13];
    const score = dbRoute[14];
    const isDeleted = dbRoute[15];

    if (!res[routeId]) {
      res[routeId] = {
        id: routeId,
        creationTime: timestamp,
        recordedTime: timestamp,
        lastEdited: timestamp,
        commuteId,
        raw: null,
        start: {
          lat: startLat,
          lng: startLng,
        },
        end: {
          lat: endLat,
          lng: endLng,
        },
        trafficDelay: '',
        waypoints: [],
        dist: '',
        duration: durationTransform(routeDuration),
        score,
        isDeleted,
      };
      commutes[commuteId].routesCount++;
    }

    if (index === 0) {
      totalTrafficDelay = trafficDelay;
    } else if (prevRouteEl !== roadEl || prevRouteId !== routeId) {
      if (prevReverseGeometry === 2) {
        linkGeometry.reverse();
      }
      res[prevRouteId].waypoints = res[prevRouteId].waypoints.concat(linkGeometry);
      if (prevRouteId !== routeId) {
        res[prevRouteId].trafficDelay = durationTransform(totalTrafficDelay);
        totalTrafficDelay = 0;
      }
      totalTrafficDelay += trafficDelay;
      linkGeometry = [];
    }

    const prevPoint = linkGeometry[linkGeometry.length - 1];
    if (!prevPoint || (prevPoint.lat !== lat && prevPoint.lng !== lng)) {
      linkGeometry.push({ lat, lng });
    }

    if (index === dbRoutes.length - 1) {
      if (reverseGeometry === 2) {
        linkGeometry.reverse();
      }
      res[routeId].waypoints = res[routeId].waypoints.concat(linkGeometry);
      res[routeId].trafficDelay = durationTransform(totalTrafficDelay + trafficDelay);
      commutes[commuteId].routesCount++;
    }

    prevReverseGeometry = reverseGeometry;
    prevRouteEl = roadEl;
    prevRouteId = routeId;
  });

  Object.keys(res).forEach(id => {
    let totalDistance = 0;
    const routeLength = res[id].waypoints.length;
    res[id].waypoints.forEach((waypoint, index) => {
      if (index !== routeLength - 1) {
        totalDistance += distanceBetweenPoints(
          { lat: waypoint.lat, lng: waypoint.lng },
          { lat: res[id].waypoints[index + 1].lat, lng: res[id].waypoints[index + 1].lng },
        );
      }
    });
    res[id].dist = distTransform(totalDistance);

    const firstRoadElementId = groupedDbRoutes[id][0][16];
    const lastRoadElementId = groupedDbRoutes[id][groupedDbRoutes[id].length - 1][16];
    const firstLinkRecords = [];
    const lastLinkRecords = [];

    while(groupedDbRoutes[id][firstLinkRecords.length][16] === firstRoadElementId) {
      firstLinkRecords.push(groupedDbRoutes[id][firstLinkRecords.length]);
    }
    firstLinkRecords.reverse();

    while(groupedDbRoutes[id][groupedDbRoutes[id].length - lastLinkRecords.length - 1][16] === lastRoadElementId) {
      lastLinkRecords.push(groupedDbRoutes[id][groupedDbRoutes[id].length - lastLinkRecords.length - 1]);
    }
    lastLinkRecords.reverse();

    const pointsToLeaveInTheBeginning = cutOffExtraPoints(firstLinkRecords, res[id].start).reverse();
    firstLinkRecords.reverse();

    const pointsToLeaveInTheEnd = cutOffExtraPoints(lastLinkRecords, res[id].end);

    const offsetLeft = firstLinkRecords.length - pointsToLeaveInTheBeginning.length;
    const offsetRight = lastLinkRecords.length - pointsToLeaveInTheEnd.length;

    const slicedWaypoints = res[id].waypoints.slice(offsetLeft, -offsetRight || undefined);
    res[id].waypoints = [res[id].start, ...slicedWaypoints, res[id].end];
  });

  return res;
}
