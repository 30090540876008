const NS = 'state';

function defaultParser(item, defaultState) {
  const storedState = JSON.parse(item || '{}');
  return Object.assign({}, defaultState, storedState);
}

export default function (reducer, key, defaultState = {}, parser = defaultParser) {
  const fullKey = `${NS}.${key}`;
  const item = localStorage.getItem(fullKey);
  const initialState = parser(item, defaultState);
  return (state = initialState, action) => {
    const newState = reducer(state, action);
    localStorage.setItem(fullKey, JSON.stringify(newState));
    return newState;
  };
}
