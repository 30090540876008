import React, {Component, Children, cloneElement} from 'react';
import './map.css'
import {initMap} from './utils';

export default class Map extends Component {
  constructor() {
    super();
    this.state = {
      map: null,
    };
    this.resizeMap = this.resizeMap.bind(this);
    this.isReduced = false;
  }
  componentDidMount() {
    const map = initMap(this.mapEl);
    window.addEventListener('resize', this.resizeMap);
    this.setState({ map })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeMap);
  }

  render() {
    const { selectedRouteId = null, selectedPlaceId = null, selectedCommuteId = null } = this.props;
    const childrenWithProps = Children.map(
      this.props.children,
      child => cloneElement(child, { map: this.state.map }),
    );

    const isReduced = (+(selectedRouteId !== null) + (selectedPlaceId !== null) + (selectedCommuteId !== null)) > 1;
    if (this.isReduced !== isReduced) {
      setTimeout(() => window.dispatchEvent(new Event('resize')), 1); // dispatch event after rendering
      this.isReduced = isReduced;
    }

    return (
      <div>
        <div
          className={'mg-map' + (isReduced ? ' reduced' : '')}
          ref={(el) => { this.mapEl = el; }}
        />
        {childrenWithProps}
      </div>
    );
  }

  resizeMap() {
    this.state.map.getViewPort().resize();
  }
}
