import React, {Component} from 'react';
import './radio-group.css';

export default class RadioGroup extends Component {
  render() {
    const options = this.props.options.map((option, index) => (
      <label className="mg-radio-option" key={index}>
        <input
          type="radio"
          name={this.props.name || 'radio'} value={option.value}
          checked={option.value === this.props.value}
          onChange={(e) => this.props.onChange(e.target.value)}
        />
        <i />
        <span> {option.label}</span>
      </label>
    ));
    return (
      <div className="mg-radio-group">
        {options}
      </div>
    );
  }
}
