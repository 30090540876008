import {LOGOUT} from '../../user/reducer';

const NS = 'predictions/hoveredPrediction';
const SET = `${NS}/SET`;

export default function hoveredPrediction(state = null, action) {
  switch (action.type) {
    case SET:
      return action.payload;
    case LOGOUT:
      return null;
    default:
      return state;
  }
}

export function setHoveredPrediction(predictionId) {
  return { type: SET, payload: predictionId };
}
