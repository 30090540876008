export default `
<event>
  <service>pnav_notifications_v1</service>
  <task>delay_warning</task>
  <timestamp>2018-08-23T13:35:00Z</timestamp>
  <servicedata>
    <parameter name="destinationId" type="String">1231242135345</parameter>
    <parameter name="routeId" type="String">53453452345</parameter>
    <parameter name="destinationGeometricLocation" type="String">51.15,13.31</parameter>
    <parameter name="destinationNamingLocation" type="String">51.15,13.31</parameter>
    <parameter name="carLocation" type="String">51.15,13.31</parameter>
    <parameter name="notificationTime" type="String">2018-07-27T13:35:00Z</parameter>
    <parameter name="notificationExpiryTime" type="String">2018-07-27T17:35:00Z</parameter>
    <parameter name="estimatedTravelTimeWithoutTrafficSeconds" type="Integer">1000</parameter>
    <parameter name="estimatedTravelTimeWithTrafficSeconds" type="Integer">1500</parameter>
    <parameter name="historicalTrafficDelaySeconds" type="Integer">700</parameter>
  </servicedata>
</event>
`;
