import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'font-awesome/css/font-awesome.css';
import './style.css';
import { addressParser } from "../../../state/cloudSyncData/table-parsers/common-parsers";
import * as _ from 'lodash';

export default class StartEnd extends Component {
  state = {
    startAddress: '...',
    endAddress: '...',
  };

  componentDidMount() {
    this.loadAddress(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.start, this.props.start) || !_.isEqual(prevProps.end, this.props.end)) {
      this.loadAddress(this.props);
    }
  }

  loadAddress(props) {
    addressParser(props.start).subscribe(startAddress => {
      this.setState({ startAddress });
    });
    if (props.end) {
      addressParser(props.end).subscribe(endAddress => {
        this.setState({ endAddress });
      });
    }
  }

  render() {
    const { start, end } = this.props;

    return <div className="mg-start-end">
      {start &&
        <div className="from">
          <div className="icon">A</div>
          <div className="content">
            <div className="address">{this.state.startAddress}</div>
            <div className="coords">{start.lat},{start.lng}</div>
          </div>
          {end && <i className="double-arrows-icon" />}
        </div>
      }

      {end &&
        <div className="to">
          <div className="icon">B</div>
          <div className="content">
            <div className="address">{this.state.endAddress}</div>
            <div className="coords">{end.lat},{end.lng}</div>
          </div>
        </div>
      }
  </div>;
  }
}

StartEnd.propTypes = {
  start: PropTypes.object,
  end: PropTypes.object,
};
