import { combineReducers } from 'redux';
import formData from './formData/reducer';
import response from './response/reducer';
import hoveredPrediction from './hoveredPrediction/reducer';
import hoveredPredictionRoute from './hoveredRoute/reducer';
import selectedPrediction from './selectedPrediction/reducer';

export default combineReducers({
  formData,
  response,
  hoveredPrediction,
  hoveredPredictionRoute,
  selectedPrediction,
});
