import {addressParser, coordsParser, numberParser} from './common-parsers';

export const PLACE_PARSERS = {
  headers: [
    'Address',
    'Coordinates',
    '# of commutes',
    'Total Score',
  ],
  parsers: {
    'Address': { path: `point`, parser: addressParser, async: true },
    'Coordinates': { path: `point`, parser: coordsParser },
    '# of commutes': { path: `commutesCount` },
    'Total Score': { path: `score`, parser: numberParser },
  },
};
