import React, { Component } from 'react';
import {connect} from 'react-redux';
import './header.css';
import {Link, withRouter} from 'react-router-dom';
import Nav from './nav/Nav';
import {getIsLoggedIn, getUser} from '../../state/user/getters';
import {logout} from '../../state/user/reducer';
import {bindActionCreators} from 'redux';
import {closeFile} from '../../state/file/reducer';
import {getFile} from '../../state/file/getters';
import UserMenuToggler from "../user-menu/UserMenuToggler";
import FileHeader from "./fileHeader/FileHeader";
import UIZoom from "../ui-zoom/UIZoom";
import { getUiZoom } from "../../state/ui/uiZoom/getters";
import { setUiZoom } from "../../state/ui/uiZoom/reducer";

class Header extends Component {
  render() {
    return (
      <header className="mg-header">
        <Link to="/"><div className="mg-logo" /></Link>

        {this.getNavEl()}
        {this.getUIZoomEl()}
        {this.props.isLoggedIn && <UserMenuToggler
          isLoggedIn={this.props.isLoggedIn}
          logout={this.props.logout}
          user={this.props.user}
          lastSync={this.props.lastSync}
        />}
        {this.getCloseFile()}
      </header>
    );
  }

  getNavEl() {
    return this.props.isLoggedIn ? <Nav /> : null;
  }

  getUIZoomEl() {
    return <UIZoom value={this.props.uiZoom} setZoom={this.props.setUiZoom}/>;
  }

  getCloseFile() {
    const { file } = this.props;
    return file ? <FileHeader close={this.props.closeFile} file={this.props.file} /> : null;
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
  isLoggedIn: getIsLoggedIn(state),
  file: getFile(state),
  lastSync: state.cloudSyncData ? state.cloudSyncData.lastSync : null,
  uiZoom: getUiZoom(state),
});

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch),
  closeFile: bindActionCreators(closeFile, dispatch),
  setUiZoom: bindActionCreators(setUiZoom, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header));
