import {createFinishMarker, createGroup, createMarker, createRoute, createStartMarker} from '../../shared/map/utils';
import {Drawer} from '../../shared/map/utils/drawer';
import {applyGeneralFilter} from '../../../state/cloudSyncData/table-parsers';

const hoveredRouteStyle = {
  strokeColor: 'orange',
  fillColor: 'orange',
  lineWidth: 4,
};

export class RoutesDrawer extends Drawer {
  hoveredGroup = createGroup({
    zIndex: 1,
  });

  routes = {};
  onHoverCb;
  onClickCb;

  constructor(map, group, onHoverCb, onClickCb) {
    super(map, group);
    this.onHoverCb = onHoverCb;
    this.onClickCb = onClickCb;
  }

  draw(state) {
    const mapObjects = [];
    Object.keys(state.cloudSyncData.routes).forEach(key => {
      const route = state.cloudSyncData.routes[key];
      if (!applyGeneralFilter(route, state.filters)) {
        return;
      }
      this.routes[route.id] = this.addListenersAndData(createRoute(route.waypoints), route.id);
      mapObjects.push(createMarker(route.start));
      mapObjects.push(createMarker(route.end));
      mapObjects.push(this.routes[route.id]);
    });

    this.group.addObjects(mapObjects);
  }

  update(state) {
    if (this.prevState && this.prevState.hoveredRoute !== state.hoveredRoute) {
      this.hoverCommute(state.hoveredRoute, state.cloudSyncData.routes);
    }

    super.update(state);
  }

  hoverCommute(hoveredRoute, routes) {
    this.hoveredGroup.removeAll();
    const route = this.routes[this.prevState.hoveredRoute];
    if (route) {
      route.setStyle({});
      route.setZIndex(0);
    }
    if (this.group.contains(this.hoveredGroup)) {
      this.group.removeObject(this.hoveredGroup);
    }

    if (hoveredRoute !== null) {
      const route = routes[hoveredRoute];
      this.routes[route.id].setStyle(hoveredRouteStyle);
      this.routes[route.id].setZIndex(2);
      this.hoveredGroup.addObjects([
        createStartMarker(route.start),
        createFinishMarker(route.end),
      ]);
      this.group.addObject(this.hoveredGroup);
    }
  }

  addListenersAndData(mapObject, id) {
    mapObject.addEventListener('pointerenter', this.onPointerEnter.bind(this));
    mapObject.addEventListener('pointerleave', this.onPointerLeave.bind(this));
    mapObject.addEventListener('tap', this.onClick.bind(this));
    mapObject.setData({ id });

    return mapObject;
  }

  onPointerEnter({ target }) {
    this.onHoverCb(target.getData().id);
    this.map.getElement().style.cursor = 'pointer';
  }

  onPointerLeave() {
    this.onHoverCb(null);
    this.map.getElement().style.cursor = 'auto';
  }

  onClick({ target }) {
    this.onClickCb(target.getData().id);
  }
}
