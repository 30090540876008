import React, {Component} from 'react';
import JSONTree from 'react-json-tree'

export default class Json extends Component {
  render() {
    if (!this.props.data) {
      return null;
    }
    return (
      <JSONTree
        data={this.props.data}
        theme="monokai"
        shouldExpandNode={(keyName, data, level) => level < 10}
        hideRoot
      />
    );
  }
}
