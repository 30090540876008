import moment from 'moment';

export function getLocalDate(date = new Date()) {
  let m = moment(date);
  return m.add(m.utcOffset(), 'minutes');
}

export function parseTimeZone(o) {
  let addLeadingZero = (num) => {
    let abs = Math.abs(num),
      result = String(abs < 10 ? `0${abs}` : abs);

    return `${num < 0 ? '-' : '+'}${result}`;
  };

  return typeof o === 'number' ? `${addLeadingZero(o)}:00` : o;
}

export function generateTimezoneList(range = 12) {
  let timeZoneOptions = [];

  for (let i = -range; i <= range; i++) {
    timeZoneOptions.push(parseTimeZone(i === 0 ? 'Z' : i));
  }

  return timeZoneOptions;
}

export function getWeekDay(dateString) {
  return (new Date(dateString)).getDay();
}

export function dateFormat(date) {
  return moment.utc(date).format('Y-MM-DD HH:mm:ss');
}
