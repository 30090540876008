import React, { Component } from 'react';
import './App.css';
import {Route, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from './header/Header';
import Analysis from './analysis/Analysis';
import Debug from './debug/Debug';
import { setCloudSyncData } from "../state/cloudSyncData/reducer";
import { getIsLoggedIn, getUserIdByUser } from "../state/user/getters";
import { allDataBody, defaultUrl, proxyFetch } from "../utils/fetch";
import Predictions from "./predictions/Predictions";
////////////////
import PushNotification from './push-notifications/PushNotification';
import Subscriptions from './subscriptions/Subscriptions';
//////////
import Notifications from './shared/notification/Notifications';
import { getNotification } from '../state/ui/notification/reducer';

class App extends Component {
  state = {};

  static getDerivedStateFromProps(nextProps) {
    App.setCloudSyncDataIfRequired(nextProps);
    return null;
  }

  static async setCloudSyncDataIfRequired(props) {
    if (props.cloudSyncData === null && props.isLoggedIn) {
      try {
        const body = { ...allDataBody };
        body.userid = getUserIdByUser(props.user);
        const cloudSyncData = await proxyFetch(props.user.token, defaultUrl, body);
        props.setCloudSyncData(await cloudSyncData.json());
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    if (!this.props.isLoggedIn && window.location.pathname !== '/') {
      return <Redirect to={{ pathname: "/" }} />;
    }

    return (
      <lui-desktoplayer-theme class="mg-app">
        <Header />
        <div className="mg-content">
          <Route exact path="/" component={Analysis} />
          <Route exact path="/predictions" component={Predictions} />
          <Route exact path="/debug" component={Debug} />
          {
////////////////////////////
          }
          <Route exact path="/push-notifications" component={PushNotification} />
          <Route exact path="/subscriptions" component={Subscriptions} />
          {
//////////////////////
          }
        </div>
        <Notifications notification={this.props.notification} />
      </lui-desktoplayer-theme>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: getIsLoggedIn(state),
  user: state.user,
  cloudSyncData: state.cloudSyncData,
  notification: getNotification(state),
});

const mapDispatchToProps = {
  setCloudSyncData,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(App));
