import React from 'react';
import PropTypes from 'prop-types';
import Json from '../shared/json/Json';
import './debugSubscriptions.scss';

class DebugSubscriptions extends React.Component {
  static propTypes = {
    setBody: PropTypes.func.isRequired,
    setUrl: PropTypes.func.isRequired,
    debug: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    res: PropTypes.object,
  };

  state = {
    bodyError: false,
  };

  onUrlChange = (e) => {
    this.props.setUrl(e.target.value);
  };

  onBodyChange = (e) => {
   this.props.setBody(e.target.value);
    try {
      JSON.parse(e.target.value);
      this.setState({ bodyError: false });
    } catch (e) {
      this.setState({ bodyError: true });
    }
  };

  render() {
    const { res, debug, url, body } = this.props;

    return (
      <div className="mg-debug-subscriptions">
        <h3>Debug</h3>
        <lui-desktoplayer-theme className="mg-app">

        <lui-contextchromelayer-theme>
          <lui-textfield>
            <input value={url} placeholder="Url" onChange={this.onUrlChange} />
          </lui-textfield>
          <lui-textfield class={this.state.bodyError ? 'lui-negative' : ''}>
            <textarea
              value={body}
              placeholder="Request body"
              onChange={this.onBodyChange}
            />
          </lui-textfield>
        </lui-contextchromelayer-theme>
        </lui-desktoplayer-theme>
        <div className="mg-debug-subscriptions-actions">
          <lui-button secondary onClick={debug.bind(this, 'GET')}>GET</lui-button>
          <lui-button secondary onClick={debug.bind(this, 'POST')}>POST</lui-button>
          <lui-button secondary onClick={debug.bind(this, 'PUT')}>PUT</lui-button>
          <lui-button secondary onClick={debug.bind(this, 'DELETE')}>DELETE</lui-button>
        </div>
        <div className="mg-debug-subscriptions-res">
          <Json data={res} />
        </div>
      </div>
    );
  }
}

export default DebugSubscriptions;
