import React, {Component} from 'react';
import './table.css';
import TableHead from './tableHead/TableHead';
import TableBody from './tableBody/TableBody';
import tableDataShape from './tableDataShape';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

const defaultSort = {
  sort: null,
  desc: false,
};

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: { ...defaultSort },
      data: props.data,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { values } = nextProps.data;
    const { column, desc } = prevState.sort;
    return {
      data: { ...nextProps.data, values: Table.applySort(values, column, desc) },
    };
  }

  static applySort(data, column, desc) {
    return _.orderBy(data, [`${column}.value`], desc ? 'desc' : 'asc');
  }

  onChangeSort(column) {
    const desc = this.state.sort.column === column ? !this.state.sort.desc : false;
    const sort = { column, desc };

    this.setState({
      sort,
      data: {
        ...this.state.data,
        values: Table.applySort(this.state.data.values, column, desc),
      },
    });
  }

  render() {
    return (
      <table className="mg-table">
        <TableHead
          headers={this.state.data.headers}
          sort={this.state.sort}
          onChangeSort={this.onChangeSort.bind(this)}
        />
        <TableBody
          data={this.state.data}
          hoveredId={this.props.hoveredId}
          onHover={this.props.onHover}
          onClick={this.props.onClick}
        />
      </table>
    );
  }
}

Table.defaultProps = {
  onHover: () => {},
  onClick: () => {},
};

Table.propTypes = {
  data: tableDataShape.isRequired,
  hoveredId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onHover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
