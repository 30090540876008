import React, {Component} from 'react';
import './spinner.css'

export default class Spinner extends Component {
  render() {
    return (
      <div className="mg-spinner-container">
        <span className="mg-spinner" />
      </div>
    );
  }
}
