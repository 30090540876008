const NS = 'hoveredRoute';
const SET = `${NS}/SET`;

export default function (state = null, action) {
  switch (action.type) {
    case SET:
      return action.payload;
    default:
      return state;
  }
}

export function setHoveredRoute(routeId) {
  return { type: SET, payload: routeId };
}
