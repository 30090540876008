import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseFloatingPanel from "../closeFloatingPanel/index";
import StartEnd from "../startEnd/index";
import Collapse from "../../collapse/index";
import Table from '../../shared/table/Table';
import './style.css';
import Json from '../../shared/json/Json';

export default class SelectedPlace extends Component {
  render() {
    const { selectedPlace, onClose, hoveredId } = this.props;

    return <div>
      <CloseFloatingPanel onClose={onClose}/>

      <h2>Place id: {selectedPlace.place.id}</h2>

      <StartEnd start={selectedPlace.place.point}/>

      <Collapse label="View json object" hidden={!selectedPlace.place.raw}>
        <Json
          data={selectedPlace.place.raw}
        />
      </Collapse>

      <Table
        data={selectedPlace.tableData}
        onClick={this.onSelectObject.bind(this)}
        onHover={this.onHoverObject.bind(this)}
        hoveredId={hoveredId}
      />

    </div>;
  }

  onSelectObject(id){
    return this.props.selectCommute(id);
  }

  onHoverObject(id) {
    return this.props.setHoveredCommute(id);
  }
}

SelectedPlace.propTypes = {
  selectedPlace: PropTypes.object.isRequired,
  hoveredId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  selectCommute: PropTypes.func.isRequired,
  setHoveredCommute: PropTypes.func.isRequired,
};
