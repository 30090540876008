import { SET as SET_HOST } from '../host/constants';
import { proxyFetch } from '../../../utils/fetch';
import { getUser } from '../../user/getters';
import { getSubscriptionsHost } from '../host/reducer';
import { setSubscrIsLoading } from '../ui/reducer';
import { errorNotification } from '../helpers';

const SET = 'subscriptions/isActive/SET';

export default (state = null, action) => {
  switch (action.type) {
    case SET_HOST:
      return null;
    case SET:
      return action.payload;
    default:
      return state;
  }
}

export const loadService = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const res = await proxyFetch(getUser(state).token, `${getSubscriptionsHost(state)}/services/traffic_notifications`, null, 'GET');
      if (res.status >= 400) {
        await errorNotification(dispatch, res);
      } else {
        const json = await res.json();
        dispatch({ type: SET, payload: json.active });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const toggleService = (isActive) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const body = { active: isActive };
      dispatch(setSubscrIsLoading('isActive', true));
      const res = await proxyFetch(
        getUser(state).token,
        `${getSubscriptionsHost(state)}/services/traffic_notifications`,
        body,
        'PUT',
      );
      if (res.status >= 400) {
        await errorNotification(dispatch, res);
      } else {
        const json = await res.clone().json();
        dispatch({ type: SET, payload: json.active });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setSubscrIsLoading('isActive', false));
    }
  }
};

export const getIsServiceActive = (state) => state.subscriptions.isActive;
