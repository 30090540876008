import React from 'react';
import PropTypes from 'prop-types';
import RemoveSubscription from './RemoveSubscription';
import MGMarker from '../shared/leafletMap/MGMarker';

class SubscriptionMarkers extends React.Component {
  static propTypes = {
    subscriptions: PropTypes.array,
    deleteSubscription: PropTypes.func.isRequired,
  };

  state = {
    clickedMarkerPos: null,
    clickedMarkerId: null,
  };

  onMarkerClick = (subscription, e) => {
    this.setState({
      clickedMarkerPos: e.containerPoint,
      clickedMarkerId: subscription.id,
    });
    e.originalEvent.skipClose = true;
    document.addEventListener('click', this.onCloseMarkerClick);
  };

  onCloseMarkerClick = (e) => {
    if (e.skipClose) {
      return;
    }
    this.setState({
      clickedMarkerPos: null,
    });
    document.removeEventListener('click', this.onCloseMarkerClick);
  };


  render() {
    const { subscriptions, deleteSubscription } = this.props;
    if (subscriptions === null) {
      return null;
    }

    const { clickedMarkerId, clickedMarkerPos } = this.state;

    return (
      <React.Fragment>
        {subscriptions.map((subscription) => (
          <MGMarker
            key={subscription.id}
            onClick={this.onMarkerClick.bind(this, subscription)}
            position={[subscription.location.lat, subscription.location.lon]}
          />
        ))}
        {clickedMarkerPos && <RemoveSubscription id={clickedMarkerId} pos={clickedMarkerPos} onRemove={deleteSubscription} />}
      </React.Fragment>
    );
  }
}

export default SubscriptionMarkers;
