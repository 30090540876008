import PropTypes from 'prop-types';

export const tableRowShape = PropTypes.objectOf(PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]).isRequired,
  parser: PropTypes.func,
}));

export default PropTypes.shape({
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(tableRowShape).isRequired,
});
