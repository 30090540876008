import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import CloseFloatingPanel from "../closeFloatingPanel/index";
import StartEnd from "../startEnd/index";
import Collapse from "../../collapse/index";

import './style.css';
import ScoreDistribution from "../scoreDistribution/index";
import Json from '../../shared/json/Json';

export default class SelectedCommute extends Component {
  getTimeString(timeStamp) {
    return moment(timeStamp).format('Y-MM-DD, HH:mm a');
  }

  render() {
    const { selectedCommute, onClose } = this.props;

    return <div className="mg-floating-selected-commute">
      <CloseFloatingPanel onClose={onClose} />

      <h2>COMMUTE ID: {selectedCommute.commute.id}</h2>

      <StartEnd start={selectedCommute.commute.start} end={selectedCommute.commute.end} />

      <Collapse label="View json object" hidden={!selectedCommute.commute.raw}>
        <Json data={selectedCommute.commute.raw} />
      </Collapse>

      <h2>Commute Routes</h2>

      <table className="routes">
        <tbody>
          {selectedCommute.routes.map((item, index) =>
            <tr key={index}>
              <td><i className="icon" /></td>
              <td>
                {item.dist}
                <i>Status:</i>
                <i>{item.isDeleted ? '' : 'not '}deleted</i>
              </td>
              <td>
                for {item.duration}
                <i>Recorded:</i>
                <i>{this.getTimeString(item.recordedTime)} (UTC time)</i>
              </td>
              <td>
                &nbsp;
                <i>Creation time:</i>
                <i>{this.getTimeString(item.creationTime)} (UTC time)</i>
              </td>
              <td>
                score: {item.score}
                <i>Last edited:</i>
                <i>{this.getTimeString(item.lastEdited)} (UTC time)</i>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <h2>Commute Score Distribution</h2>
      <ScoreDistribution selectedCommute={selectedCommute}/>
    </div>;
  }
}

SelectedCommute.propTypes = {
  selectedCommute: PropTypes.object.isRequired,
};
