import React, { Component } from 'react';
import './left.css';

export default class Left extends Component {
  render() {
    return (
      <aside className="mg-left">{this.props.children}</aside>
    )
  }
}
