import React from 'react';
import { Marker } from 'react-leaflet';
import { icon } from 'leaflet';
import { getDefaultMarker } from '../map/utils/markers-svg';

const iconUrl = 'data:image/svg+xml;base64,' + window.btoa(getDefaultMarker());
const defaultIcon = icon({ iconUrl, iconSize: [28, 36], iconAnchor: [12, 36] });

class MGMarker extends React.Component {
  static propTypes = Marker.propTypes;

  render() {
    return (
      <Marker {...this.props} icon={defaultIcon} />
    );
  }
}

export default MGMarker;
