import H from '@here/maps-api-for-javascript';
import '@here/maps-api-for-javascript/bin/mapsjs-ui.css';
import * as moment from 'moment';
import {getDefaultMarker, FINISH_MARKER_SVG, START_MARKER_SVG} from './markers-svg';

export const START_ICON = new H.map.Icon(START_MARKER_SVG);
export const FINISH_ICON = new H.map.Icon(FINISH_MARKER_SVG);
export const DEFAULT_ICON = new H.map.Icon(getDefaultMarker());

export const getDefaultIcon = (label, color) => new H.map.Icon(getDefaultMarker(label, color));

export function initMap(element) {
  const platform = new H.service.Platform({
    apikey: 'r41fZWD11-du0YDYvnIp0BnYR6yStYx4vl1AeE_tPcM',
    // useCIT: true,
    useHTTPS: true
  });

  const defaultLayers = platform.createDefaultLayers();
  const map = new H.Map(element, defaultLayers.vector.normal.map);
  // tslint:disable-next-line:no-unused-expression
  new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
  H.ui.UI.createDefault(map, defaultLayers);
  moveMapToBerlin(map);

  return map;
}

function moveMapToBerlin(map) {
  map.setCenter({lat: 52.5159, lng: 13.3777});
  map.setZoom(14);
}

export function createGroup(options) {
  return new H.map.Group(options);
}

export function createStartMarker(point) {
  return createMarker(point, {
    zIndex: 1,
    icon: START_ICON,
  });
}

export function createFinishMarker(point) {
  return createMarker(point, {
    zIndex: 2,
    icon: FINISH_ICON,
  });
}

export function durationTransform(durationSec) {
  const duration = moment.duration(durationSec, 'seconds');
  if (duration.hours() > 0) {
    return `${duration.hours()} h ${duration.minutes()} min`;
  }

  return `${duration.minutes()} min ${duration.seconds()} sec`;
}

export function distTransform(dist) {
  const kmDist = dist / 1000;
  if (kmDist > 1) {
    return `${kmDist.toFixed(3)} km`;
  }
  return `${dist.toFixed(3)} m`;
}

export function createMarker(point, options) {
  return new H.map.Marker(point, options);
}

export const COMMUTE_LINE_OPTIONS = {
  style: {
    lineDash: [4, 10],
  },
};

export function createCommuteRoute(commute, options) {
  return createRoute([commute.start, commute.end], {
    ...COMMUTE_LINE_OPTIONS,
    ...options,
  });
}

export function createRoute(waypoints, options = {}) {
  const shape = waypoints.map(waypoint => [waypoint.lat, waypoint.lng])
    .reduce((red, val) => [...red, ...val], []);

  return new H.map.Polyline(createStrip(shape), options);
}

function createStrip(shape = []) {
  const strip = new H.geo.LineString();
  const shapeLength = shape.length;

  if (shapeLength % 2 !== 0 || shapeLength < 4) {
    throw new Error('Invalid shape.');
  }

  for (let i = 0; i < shapeLength; i += 2) {
    strip.pushLatLngAlt(shape[i], shape[i + 1]);
  }

  return strip;
}

export function createCircle(latLng, radiusMeters, style = {}) {
  return new H.map.Circle(
    latLng,
    radiusMeters,
    {
      style: {
        strokeColor: 'rgba(0, 0, 0, 0.4)',
        fillColor: 'rgba(0, 0, 0, 0.1)',
        lineWidth: 1,
        ...style,
      }
    }
  );
}

export function distanceBetweenPoints(point1, point2) {
  return new H.geo.Point(point1.lat, point1.lng).distance(new H.geo.Point(point2.lat, point2.lng));
}
