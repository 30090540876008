import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from '../shared/slider/Slider';

import './style.css';

class UIZoom extends Component {
  state = {
    prevProps: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.prevProps || nextProps.value !== prevState.prevProps.value) {
      UIZoom.applyZoom(nextProps.value);
    }
    return {
      prevProps: nextProps,
    };
  }

  static applyZoom(uiZoom) {
    document.documentElement.style.fontSize = `${uiZoom}px`;
    window.dispatchEvent(new Event('resize'));
  }

  onChange(uiZoom) {
    this.props.setZoom(uiZoom[0]);
  }

  render() {
    return <div className="mg-app-zoom">
      <span className="caption">UI Size:</span>
      <Slider
        defaultValue={[this.props.value]}
        min={5}
        max={15}
        onAfterChange={this.onChange.bind(this)}
      />
    </div>;
  }
}

UIZoom.propTypes = {
  value: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
};

export default UIZoom;
