import React from 'react';
import { Map, TileLayer } from 'react-leaflet';
import './leafletMap.scss';

class LeafletMap extends React.Component {
  static propTypes = Map.propTypes;

  render() {
    return (
      <div className="leaflet-map">
        <Map {...this.props} maxZoom={20}>
          <TileLayer
            url="http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=VgTVFr1a0ft1qGcLCVJ6&app_code=LJXqQ8ErW71UsRUK3R33Ow"
          />{this.props.children}
        </Map>
      </div>
    );
  }
}

export default LeafletMap;
