import React from 'react';
import PropTypes from 'prop-types';

class SubscriptionList extends React.Component {
  static propTypes = {
    subscriptions: PropTypes.array,
  };

  render() {
    const { subscriptions } = this.props;
    if (subscriptions === null) {
      return null;
    }

    return (
      <div>
        <span>Subscriptions</span>
        <ul>
          {subscriptions.map((subscription) => (
            <li key={subscription.id}>{subscription.location.lat}, {subscription.location.lon}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default SubscriptionList;
