import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {tableRowShape} from '../tableDataShape';
import Cell from './Cell';

export default class Row extends Component {
  render() {
    const { headers, row } = this.props;
    return headers.map(header => (
      <Cell
        key={header}
        value={row[header].value}
        parser={row[header].parser}
        async={row[header].async}
      />
    ));
  }
}

Row.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  row: tableRowShape,
};
